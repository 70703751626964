import { SlackAccount } from "#webapp/src/components/slack-account";
import { SlackButton } from "#webapp/src/components/slack-button";
import {
  IOnboardingChecklistSyncType,
  setSavedOnboardingChecklistSyncType,
} from "#webapp/src/state/actions/settings.actions";
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";
import { track, TrackingEvents } from "#webapp/src/util/analytics.util";
import { cleanUpSlackNonce } from "#webapp/src/util/third-party-oauth.util";
import { Tabs } from "@clockwise/design-system";
import { Warning } from "@clockwise/design-system/icons";
import * as React from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { IOrg, ISlackAccount, ISlackSettings } from "../../__schema__";
import * as s from "./SlackSettings.styles";
import { IContainer, IProps, IState } from "./SlackSettingsTypes";

const SlackSettingTabContent = ({
  org,
  settings,
  account,
  refetch,
  onAuthPopupClosed,
}: {
  org: IOrg;
  settings?: ISlackSettings[];
  account: ISlackAccount;
  refetch: () => void;
  onAuthPopupClosed: () => void;
}) => {
  const onDeleteSlackAccount = () => {
    track(TrackingEvents.SLACK.DELETE);
    refetch();
  };
  const sl =
    (settings &&
      settings.find(
        (ss) => ss.slackUserId === account.slackUserId && ss.slackTeamId === account.slackTeamId,
      )) ||
    null;
  if (!sl) {
    return null;
  }
  return (
    <div
      key={account.id}
      className="cw-border cw-border-solid cw-rounded-md cw-border-muted cw-p-4"
    >
      <SlackAccount
        org={org}
        slackAccount={account}
        slackSettings={sl}
        onAuthPopupClosed={onAuthPopupClosed}
        onDelete={onDeleteSlackAccount}
        onUpdate={refetch}
      />
    </div>
  );
};

class SlackSettingsCmpt extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  private onAuthPopupClosed = () => {
    cleanUpSlackNonce();
    this.props.refetch();
  };

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    if (
      this.props.org.slackAccountsErrorable.__typename !== "GraphEntityError" &&
      nextProps.org.slackAccountsErrorable.__typename !== "GraphEntityError"
    ) {
      const accountCount = this.props.org.slackAccountsErrorable.list.length || 0;
      const nextAccountCount = nextProps.org.slackAccountsErrorable.list.length || 0;
      if (nextAccountCount > accountCount) {
        track(TrackingEvents.SLACK.ADDED);
        toast.success("Your Slack account was added!");
        this.props.setSavedOnboardingChecklistSyncType(IOnboardingChecklistSyncType.slackEnabled);
        this.setState({ selectedTab: nextAccountCount - 1 });
      }
      if (nextAccountCount >= this.state.selectedTab) {
        this.setState({ selectedTab: 0 });
      }
    }
  }

  public render() {
    const { org } = this.props;
    const accounts =
      org.slackAccountsErrorable.__typename !== "GraphEntityError" &&
      org.slackAccountsErrorable.list;
    const settings =
      (org.slackSettingsErrorable.__typename !== "GraphEntityError" &&
        org.slackSettingsErrorable.list) ||
      undefined;

    return (
      <div>
        {accounts && accounts.length && (
          <div style={s.slackAccounts}>
            <div>
              <Tabs
                defaultTabIndex={0}
                tabs={accounts.map((a, index) => ({
                  title: (
                    <span>
                      {a.needsUpdate ? <Warning style={s.needsUpdate} /> : null}
                      {a.teamDisplayName}
                    </span>
                  ),
                  content: (
                    <SlackSettingTabContent
                      org={org}
                      settings={settings}
                      refetch={this.props.refetch}
                      onAuthPopupClosed={this.onAuthPopupClosed}
                      account={a}
                      key={index}
                    />
                  ),
                  key: a.slackTeamId,
                }))}
              />
            </div>
          </div>
        )}
        <div style={s.addSlack}>
          <SlackButton onAuthPopupClosed={() => this.onAuthPopupClosed()} />
        </div>
      </div>
    );
  }
}

//////////////////
// REDUX
//////////////////
function mapStateToProps(_state: IReduxState, _ownProps: IContainer) {
  return {};
}

export const SlackSettings = connect(mapStateToProps, {
  setSavedOnboardingChecklistSyncType,
})(SlackSettingsCmpt);
