import { Tooltip } from "@clockwise/design-system";
import classNames from "classnames";
import React, { useRef } from "react";
import { useBoolean } from "usehooks-ts";
import { putCaretAtEndOfInput } from "../../util/chatInput.util";
import { CancelProcessMessageButton } from "./CancelProcessMessageButton";
import "./ChatBox.css";
import { ChatInputLoading } from "./ChatInputLoading";
import { ChatInputTextArea } from "./ChatInputTextArea";
import { deferredCallback } from "./utils/deferredCallback";
type OnSubmit = (innerText: string) => Promise<void>;

export type Props = {
  disabled?: boolean;
  disabledReason?: string;
  focusInput?: boolean;
  loading?: boolean;
  onCancel: () => void;
  onSubmit: OnSubmit;
  onFocusCallback?: () => void;
  size?: "small" | "large";
};

export const RefineInput = ({
  disabled,
  disabledReason,
  focusInput,
  loading,
  onCancel,
  onSubmit,
  onFocusCallback,
  size,
}: Props) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    value: isInputFocused,
    setTrue: setInputFocused,
    setFalse: setInputNotFocused,
  } = useBoolean(true);

  const focusCallback = () => {
    setInputFocused();
    onFocusCallback?.();
  };

  const blurCallback = () => {
    setInputNotFocused();
  };

  const clearInput = () => {
    if (inputRef.current) {
      inputRef.current.innerText = "";
    }

    deferredCallback(() => putCaretAtEndOfInput(inputRef.current));
  };

  const submitMessage = async () => {
    const input = inputRef.current;

    if (!input) {
      return;
    }

    const text = input.innerText.trim();

    if (text.length === 0) {
      return;
    }

    await onSubmit(text);

    // Clear the input
    clearInput();
  };

  return (
    <Tooltip title={disabled && disabledReason ? disabledReason : ""}>
      <div className="cw-group/ChatInput" ref={containerRef}>
        <div
          className={classNames(
            "cw-transition ease-in-out",
            "cw-outline-transparent group-focus-within/ChatInput:cw-border-positive-interactable",
            isInputFocused
              ? "group-hover/ChatInput:cw-shadow-sm"
              : "group-hover/ChatInput:cw-shadow-md",
            "cw-rounded-xl",
            "cw-overflow-hidden",
            "cw-flex cw-flex-col",
            "cw-body-base",
            "cw-bg-default",
            "cw-shadow-sm group-focus-within/ChatInput:cw-shadow-none",
            {
              "cw-border-default cw-outline cw-border cw-border-solid": !loading,
              "cw-prism-outline cw-prism-outline-loading ": loading,
            },
          )}
        >
          <div className="cw-prism-mask cw-rounded-xl" />
          <div
            className={classNames(
              "cw-flex cw-flex-row cw-space-x-1 cw-justify-start cw-items-center",
            )}
          >
            {!loading ? (
              <>
                <ChatInputTextArea
                  autoFocus={focusInput}
                  disabled={disabled}
                  inputRef={inputRef}
                  onBlur={blurCallback}
                  onFocus={focusCallback}
                  onSubmit={submitMessage}
                  placeholder={"Refine proposal"}
                  submitOnEnter
                  size={size}
                />
              </>
            ) : (
              <>
                <div className="cw-grow cw-py-2.5 cw-pl-3">
                  <ChatInputLoading />
                </div>
                <div className="cw-pr-1.5">
                  <CancelProcessMessageButton onCancel={onCancel} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Tooltip>
  );
};
