import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUpdateSuggestedPrompt } from "../chat/ai-chat/hooks/SuggestedPromptContext";
import { NUXPreferencesModal } from "./NUXPreferencesModal";
import { NUXReschedulingModal } from "./NUXReschedulingModal";
import { NUXSchedulingModal } from "./NUXSchedulingModal";

enum SearchParams {
  Preferences = "intelligent-automation",
  Scheduling = "scheduling",
  Rescheduling = "rescheduling",
}

export const NUXEducationModalWrapper = () => {
  const track = useTracking();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [mode, setMode] = useState<SearchParams | null>(null);

  const updatePromptText = useUpdateSuggestedPrompt();

  useEffect(() => {
    if (searchParams) {
      if (searchParams.get("modal") === SearchParams.Preferences) {
        setMode(SearchParams.Preferences);
      } else if (searchParams.get("modal") === SearchParams.Scheduling) {
        setMode(SearchParams.Scheduling);
      } else if (searchParams.get("modal") === SearchParams.Rescheduling) {
        setMode(SearchParams.Rescheduling);
      }
    }
  }, [searchParams]);

  const onClose = () => {
    track(TrackingEvents.EDUCATION_MODAL.DISMISSED, {
      modal: mode,
    });
    setMode(null);
    setSearchParams({});
  };

  const onClickSchedulingModalAction = () => {
    track(TrackingEvents.EDUCATION_MODAL.ACTION_CLICKED, {
      modal: mode,
    });
    updatePromptText("Schedule @");
    onClose();
  };

  const onClickReschedulingModalAction = () => {
    track(TrackingEvents.EDUCATION_MODAL.ACTION_CLICKED, {
      modal: mode,
    });
    updatePromptText("Reschedule #");
    onClose();
  };

  const onClickCalendarPreferencesModalAction = () => {
    track(TrackingEvents.EDUCATION_MODAL.ACTION_CLICKED, {
      modal: mode,
    });
    onClose();
    navigate(appPaths.accountPreferences);
  };

  const renderModalFromMode = (modalType: SearchParams | null) => {
    switch (modalType) {
      case SearchParams.Preferences:
        return (
          <NUXPreferencesModal
            onClose={onClose}
            onClickAction={onClickCalendarPreferencesModalAction}
          />
        );
      case SearchParams.Scheduling:
        return (
          <NUXSchedulingModal onClose={onClose} onClickAction={onClickSchedulingModalAction} />
        );
      case SearchParams.Rescheduling:
        return (
          <NUXReschedulingModal onClose={onClose} onClickAction={onClickReschedulingModalAction} />
        );
      default:
        return null;
    }
  };

  return renderModalFromMode(mode);
};
