import {
  CalendarEvent,
  CalendarEventSpacing,
  CalendarEventVariant,
} from "@clockwise/web-commons/src/components/calendar/calendar-event/CalendarEvent";
import { OutOfOfficeCard } from "@clockwise/web-commons/src/components/calendar/calendar-out-of-office/OutOfOfficeCards";
import {
  EventType,
  useReadActiveEvent,
  useUpdateActiveEvent,
} from "@clockwise/web-commons/src/util/ActiveEventContext";
import {
  useReadHighlightEvents,
  useUpdateHighlightEvents,
} from "@clockwise/web-commons/src/util/HighlightEventsContext";
import {
  useReadCalendarColorForCalendarId,
  useReadCalendarColors,
} from "../hooks/CalendarColorsContext";

import { removeCalendarIds } from "#webapp/src/state/actions/multi-calendar.actions";
import { ICalPosition } from "@clockwise/web-commons/src/components/calendar/calendar-positioner/types";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { useKeyListener } from "@clockwise/web-commons/src/util/react.util";
import classNames from "classnames";
import { intersection, isUndefined, negate, noop } from "lodash";
import { DateTime, Interval } from "luxon";
import { MagicWand } from "phosphor-react";
import React, { useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useReadLocalStorage } from "usehooks-ts";
import { useFeatureFlag } from "../../../launch-darkly";
import {
  useCurrentProposal,
  useUpdateCurrentProposal,
} from "../../chat-plus-calendar/CurrentProposalContext";
import { useDefragProposal } from "../../chat-plus-calendar/DefragProposalContext";
import { useUpdatePersistedProposal } from "../../chat-plus-calendar/PersistedProposalContext";
import { useUpdateActiveEventDiff } from "../../chat-plus-calendar/util/ActiveDiffContext";
import {
  useReadHoverEvent,
  useUpdateHoverEvent,
} from "../../chat-plus-calendar/util/HoverEventContext";
import { usePlannerContext } from "../Context";
import { PopperWrapper } from "../calendar-popover/PopperWrapper";
import {
  EventContextMenu,
  PersonalSyncMenu,
  SmartHoldMenu,
  WorkingLocationMenu,
} from "../event-context-menu";
import { LockedEventMenu } from "../event-context-menu/locked-event-menu/LockedEventMenu";
import { usePrefetchEventDetails } from "../hooks/useGatewayEventDetails";
import usePlannerMetaData from "../hooks/usePlannerMetaData";
import { plannerToolbarSettingKeys } from "../planner-toolbar/PlannerToolbar";
import { PlannerEventCard, TransparencyEnum } from "../types";
import { getCalendarEventStatus } from "../util/getCalendarEventStatus";
import { hasCategoryOutOfOffice } from "../util/hasCategoryOutOfOffice";

export type PlannerEventProps = {
  card: PlannerEventCard;
  fadedWithAWandLabel?: boolean;
  leftPad?: boolean;
  eventConsolidation?: boolean;
  columnIndex: number;
  position: ICalPosition;
  isSplitView?: boolean;
};

export const PlannerEvent = ({
  card,
  fadedWithAWandLabel = false,
  leftPad,
  eventConsolidation = true,
  columnIndex,
  position,
  isSplitView = false,
}: PlannerEventProps) => {
  const dispatch = useDispatch();
  const track = useTracking();
  const unFadePastEvents = useReadLocalStorage<boolean>(plannerToolbarSettingKeys.unFadePastEvents);
  const { onDemandDefrag } = useDefragProposal();
  const activeEvent = useReadActiveEvent();
  const updateHoverEvent = useUpdateHoverEvent();
  const currentHoverKey = useReadHoverEvent();
  const updateActiveEventDiff = useUpdateActiveEventDiff();
  const updateActiveEvent = useUpdateActiveEvent();
  const { primaryCalendarId } = usePlannerMetaData();
  const [prefetchEventDetails] = usePrefetchEventDetails();
  const { selectExistingEvent } = useUpdateCurrentProposal();
  const { discardPersistedProposal } = useUpdatePersistedProposal();
  const { currentProposal } = useCurrentProposal();
  const proposalTimeUnchanged =
    !!currentProposal &&
    !!currentProposal.eventTimeInterval &&
    currentProposal.eventTimeInterval.equals(
      Interval.fromDateTimes(currentProposal.startTime, currentProposal.endTime),
    );

  const {
    calendarIds: calendarIdsInMultiSelect = [],
    isCurrentlyShowingOptimisticProposals,
    setIsResizingAnyEvent,
    isResizingAnyEvent,
    handlePopoverChange,
    isDraggingEvent,
    scrollContainerRef,
  } = usePlannerContext();

  const {
    annotaion: annotation,
    badge,
    calendarIds = [],
    attendeesCalendarIds = [],
    deemphasis,
    diffSummaryId,
    eventCategoryColor,
    externalEventId = "",
    interval,
    key,
    subText,
    text,
    fade,
    pulse,
    responseState,
    proposalType,
    eventPermissions: { canModify },
    transparency,
    smartHold,
    type,
    locked,
    isRecurring,
    recurringEventId,
    isHold,
    isStale,
  } = card;
  const isOwnEvent = !!primaryCalendarId && calendarIds.includes(primaryCalendarId);
  const alphabetizedCalendarIds = [...attendeesCalendarIds].sort(); // This will match color order to the order in the calendar mulitselect
  const alphabetizedCalendarIdsInMultiSelect = [...calendarIdsInMultiSelect].sort(); // This will match color order to the order in the calendar mulitselect
  const calendarIdsInMultiSelectAndAttendeesIds = intersection(
    alphabetizedCalendarIdsInMultiSelect,
    alphabetizedCalendarIds,
  );
  // We need to chose the correct calendarId in order to get the correct left-most rail color
  // We always choose the left-most rail color to be the first calendarId in the multi-select
  // (whenever it's not a `isOwnEvent` event)
  const calendarIdForOthersEvents = calendarIdsInMultiSelectAndAttendeesIds[0] || calendarIds[0];

  const getCalendarId = () => {
    if (isOwnEvent) {
      return primaryCalendarId;
    }
    return eventConsolidation ? calendarIdForOthersEvents : calendarIds[0];
  };

  const calendarId = getCalendarId();
  const calendarColor = useReadCalendarColorForCalendarId(calendarId, isOwnEvent);

  const calendarsColors = useReadCalendarColors();

  const eventDuration = interval.length("minutes");

  const displayedTitle = `${text.replace(" (via Clockwise)", "")}`;

  const isActive =
    externalEventId === activeEvent?.externalEventId || externalEventId === currentProposal.eventId;
  const isPast = interval.end < DateTime.local() && !unFadePastEvents;

  const highlightEvents = useReadHighlightEvents();
  const updateHighlightEvents = useUpdateHighlightEvents();
  const handleHighlightComplete = useCallback(() => {
    updateHighlightEvents((ids: string[]) => ids.filter((id) => id === externalEventId));
  }, [externalEventId, updateHighlightEvents]);
  const isHighlighted = highlightEvents.includes(externalEventId);
  const [popoverElem, setPopoverElemState] = React.useState<HTMLDivElement | null>(null);

  const setPopoverElem = (elem: HTMLDivElement | null) => {
    setPopoverElemState(elem);
    handlePopoverChange?.(!!elem);
  };

  useKeyListener(popoverElem ? ["Escape"] : [], () => setPopoverElem(null));

  const [canDragAndDropEvents] = useFeatureFlag("rollout-drag-and-drop-on-calendar");
  const [canResizeEvents] = useFeatureFlag("rollout-resize-on-calendar");

  let variant: CalendarEventVariant = "MultiLine";
  let spacing: CalendarEventSpacing = "Full";
  if (eventDuration < 30) {
    variant = "SingleLine";
    spacing = "None";
  } else if (eventDuration === 30) {
    variant = "SingleLine";
  } else if (eventDuration <= 45) {
    variant = "MultiLine";
  }

  const getStripes = () => {
    if (!eventConsolidation) {
      return undefined;
    }
    return attendeesCalendarIds.length >= 2
      ? (attendeesCalendarIds
          .filter((id) => id !== calendarId) // UI Note: don't worry the calendarId stripe will be the rail
          .filter((id) => calendarIdsInMultiSelect.includes(id)) // UI Note: Don't show stripes for calendars not in the multi-select
          .map((id) => calendarsColors[id]?.border)
          .filter(negate(isUndefined)) as string[])
      : undefined;
  };

  const setActiveEvent = () => {
    if (calendarId) {
      updateActiveEventDiff(null);
      updateActiveEvent({ calendarId, externalEventId, type });
      setPopoverElem(null);
    } else if (diffSummaryId) {
      updateActiveEvent(null);
      updateActiveEventDiff({ id: diffSummaryId });
    }
  };

  const maybeShowPopover = (event: React.MouseEvent<HTMLDivElement>) => {
    if (calendarId) {
      setPopoverElem(event.currentTarget);
    } else if (diffSummaryId) {
      updateActiveEvent(null);
      updateActiveEventDiff({ id: diffSummaryId });
    }
  };

  const onOpenEventDetailsLegacy = () => {
    track(TrackingEvents.EVENT_POPOVER.EVENT_DETAILS_OPENED);
    setActiveEvent();
  };

  const onOpenEventDetailsWithProposal = () => {
    track(TrackingEvents.DIRECT_MANIPULATION.CALENDAR.CLICK_TO_EXPAND, {
      externalEventId,
      calendarId,
    });

    selectExistingEvent(externalEventId, calendarId);
    updateActiveEventDiff(null);
    updateActiveEvent({ externalEventId: "", calendarId: "", type: EventType.Proposal });
    setPopoverElem(null);
  };

  const onOpenEventDetails = () => {
    const canDisplayProposalForEvent =
      !locked &&
      type !== EventType.WorkingLocation &&
      type !== EventType.PersonalCalendarSync &&
      !smartHold &&
      !hasCategoryOutOfOffice(card);

    function doOpen() {
      if (canDisplayProposalForEvent) {
        onOpenEventDetailsWithProposal();
      } else {
        onOpenEventDetailsLegacy();
      }
    }

    // If there is an existing proposal, clear it before opening the new event
    if (currentProposal.initialized) {
      discardPersistedProposal([
        noop,
        () => {
          // Note: This can end up with some minor weird state when the event we're going to is closed.
          // Basically, nothing will tell this other calendar to close, so it'll stay open.
          // But I think it makes sense to leave the calendar the event we're opening up belongs to open.
          // Need to dig further into how to sync state here with the checked calendar in the sidebar.
          dispatch(
            removeCalendarIds(
              currentProposal.attendees.map((a) => a.person.email).filter((a) => a !== calendarId),
            ),
          );
          doOpen();
        },
      ]);
    } else {
      doOpen();
    }
  };

  const handleDoubleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();

    // If we are displaying a proposal, show a popover on double click
    if (activeEvent && activeEvent.type === EventType.Proposal) {
      maybeShowPopover(event);
      return;
    }

    onOpenEventDetails();
  };

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      // Hiding the popover with ODD proposals for now
      if (isResizingAnyEvent || onDemandDefrag?.visible) {
        return;
      }

      const isProposal = !!proposalType;
      if (isCurrentlyShowingOptimisticProposals && isProposal) {
        toast.error("Error: Event is still being saved");
        return;
      }

      // OOO cards don't show a popover, so we need to open the event details instead
      if (hasCategoryOutOfOffice(card)) {
        onOpenEventDetails();
        return;
      }

      maybeShowPopover(event);
    },
    [isResizingAnyEvent, activeEvent],
  );

  const handleClose = () => {
    setPopoverElem(null);
  };

  useEffect(() => {
    // Clean up hover state when the component unmounts
    return () => {
      if (currentHoverKey === key) {
        updateHoverEvent(null);
      }
    };
  }, [currentHoverKey, key, updateHoverEvent]);

  if (hasCategoryOutOfOffice(card)) {
    return (
      <OutOfOfficeCard
        colorSet={calendarColor}
        fade={fade || isPast}
        headless={false}
        leftPad={leftPad}
        onClick={handleClick}
        subText={subText}
        text={displayedTitle}
      />
    );
  }

  return (
    <div
      className={classNames("cw-absolute cw-flex", "cw-h-full cw-w-full", {
        "cw-opacity-25": fadedWithAWandLabel,
        "cw-cursor-ns-resize": isResizingAnyEvent,
        "cw-cursor-wait": isStale,
      })}
      onMouseEnter={() => {
        if (proposalTimeUnchanged) {
          return;
        }

        if (proposalType && !isDraggingEvent) {
          // only update hover for proposal events
          updateHoverEvent(key);
        }
      }}
      onMouseLeave={() => {
        if (proposalType && !isDraggingEvent) {
          // only update hover for proposal events
          updateHoverEvent(null);
        }
      }}
    >
      <CalendarEvent
        active={isActive || popoverElem !== null}
        annotation={annotation}
        badge={badge}
        calendarColorSet={calendarColor}
        deemphasis={deemphasis}
        transparent={transparency === TransparencyEnum.Transparent}
        eventColorSet={isOwnEvent ? eventCategoryColor : undefined}
        fade={(isPast && !annotation) || fade} // if there is an annotation, we don't want to fade the event even if its in the past
        highlight={isHighlighted}
        onClick={handleClick}
        onHighlightComplete={handleHighlightComplete}
        spacing={spacing}
        status={getCalendarEventStatus(card)}
        pulse={pulse}
        stripes={getStripes()}
        subText={subText}
        text={displayedTitle}
        title={displayedTitle}
        variant={variant}
        isResizing={isResizingAnyEvent || false}
        setIsResizing={setIsResizingAnyEvent || noop}
        externalEventId={externalEventId}
        columnIndex={columnIndex}
        interval={interval}
        position={position}
        canDragAndDrop={canDragAndDropEvents}
        canResize={canResizeEvents}
        onMouseOver={() => {
          if (card.externalEventId && calendarId) {
            void prefetchEventDetails({
              variables: { externalEventId: card.externalEventId, calendarId },
            });
          }
        }}
        onDoubleClick={handleDoubleClick}
        canModify={canModify}
        calendarId={calendarId}
        canDragAcrossColumns={!isSplitView}
        isRecurring={isRecurring}
        recurringEventId={recurringEventId}
        isHold={isHold}
        isStale={isStale}
      />

      {fadedWithAWandLabel && (
        <div className="cw-flex cw-items-center cw-gap-1 cw-mb-1 cw-font-bold cw-absolute cw-right-0 cw-bg-[#d3f7d9] cw-z-50">
          <MagicWand color="#4459dd" weight="fill" size="14px" />
        </div>
      )}
      {popoverElem && (
        <PopperWrapper
          anchorEl={popoverElem}
          boundary={scrollContainerRef?.current ?? undefined}
          onClose={handleClose}
        >
          {locked ? (
            <LockedEventMenu
              externalEventId={externalEventId}
              calendarId={calendarId}
              title={displayedTitle}
              onClose={handleClose}
              onOpenCard={onOpenEventDetails}
            />
          ) : type === EventType.WorkingLocation ? (
            <WorkingLocationMenu
              calendarId={calendarId}
              eventId={externalEventId}
              onClose={handleClose}
              onOpenCard={onOpenEventDetails}
            />
          ) : smartHold ? (
            <SmartHoldMenu
              smartHold={smartHold}
              title={displayedTitle}
              interval={interval}
              onClose={handleClose}
              onOpenCard={onOpenEventDetails}
              calendarId={calendarId}
              externalEventId={externalEventId}
              isOwnSmartHold={card.eventPermissions.canRemove}
            />
          ) : type === EventType.PersonalCalendarSync ? (
            <PersonalSyncMenu
              calendarId={calendarId}
              eventId={externalEventId}
              isOwnEvent={card.eventPermissions.canRemove}
              onClose={handleClose}
              onOpenCard={onOpenEventDetails}
            />
          ) : (
            <EventContextMenu
              responseState={responseState}
              title={displayedTitle}
              externalEventId={externalEventId}
              startTime={interval.start}
              onClose={handleClose}
              onOpenCard={onOpenEventDetails}
              calendarId={calendarId}
              videoLink={card.videoLink}
              eventPermissions={card.eventPermissions}
              duration={eventDuration}
            />
          )}
        </PopperWrapper>
      )}
    </div>
  );
};
