import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { AttendeeAvatarStack } from "@clockwise/web-commons/src/ui/AttendeeAvatarStack";
import React from "react";
import { TeamMember, getNamesList } from "./TeamJoinCreateStep.util";

export const TeamCard = ({
  teamName,
  teamMembers,
}: {
  teamName: string;
  teamMembers: TeamMember[];
}) => {
  const visibleTeamNames = getNamesList(teamMembers);

  return (
    <div className="cw-flex cw-w-full cw-items-center cw-justify-between cw-bg-default cw-rounded-md">
      <div>
        <div className="cw-body-base cw-font-bold cw-text-accent">{teamName}</div>
        <span className="cw-body-sm cw-text-subtle">{visibleTeamNames}</span>
      </div>
      <AttendeeAvatarStack size="xlarge" overlap maxShown={3}>
        {teamMembers.map((member, index) => (
          <AttendeeAvatar
            key={`${member.profile?.familyName} ${index}`}
            profile={member.profile || undefined}
            size="xlarge"
            isInOverlap
          />
        ))}
      </AttendeeAvatarStack>
    </div>
  );
};
