import { ElectricBoltFilled, ScatterPlotFilled, SettingsFilled } from "@clockwise/icons";
import React from "react";
import { ContentContainer, ContentRow, Divider, EducationModal } from "../education-modal";
import { CALENDAR_PREFERENCES_VIDEO_URL } from "../superpowers-checklist/videos";

const PrimaryContent = (
  <ContentContainer>
    <ContentRow>
      <SettingsFilled className="cw-text-busy cw-w-4 cw-h-4" />
      <div className="cw-text-13 cw-leading-5">
        Places events on your calendar{" "}
        <span className="cw-font-medium">only within the settings</span> you provide
      </div>
    </ContentRow>
    <Divider />
    <ContentRow>
      <ElectricBoltFilled className="cw-text-busy cw-w-4 cw-h-4" />
      <div className="cw-text-13 cw-leading-5">
        As your week changes, Clockwise will make{" "}
        <span className="cw-font-medium">real-time adjustments</span>
      </div>
    </ContentRow>
    <Divider />
    <ContentRow>
      <ScatterPlotFilled className="cw-text-busy cw-w-4 cw-h-4" />
      <div className="cw-text-13 cw-leading-5">
        Make sense of your calendar with{" "}
        <span className="cw-font-medium">automatic event color-coding</span> based on smart
        categories
      </div>
    </ContentRow>
  </ContentContainer>
);

export const NUXPreferencesModal = ({
  onClose,
  onClickAction,
}: {
  onClose: () => void;
  onClickAction: () => void;
}) => {
  return (
    <EducationModal
      header="Intelligent automation"
      subheader="Design your ideal week with helpful automations for your calendar, from meeting breaks to lunch"
      primaryButtonText="Set your Ideal Day"
      mainContent={PrimaryContent}
      isOpen={true}
      onClose={onClose}
      onClickPrimaryButton={onClickAction}
      onClickSecondaryButton={onClose}
      videoLink={CALENDAR_PREFERENCES_VIDEO_URL}
    />
  );
};
