import { Button, Tooltip } from "@clockwise/design-system";
import { Close } from "@clockwise/icons";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { noop } from "lodash";
import pluralize from "pluralize";
import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { usePersistedProposal } from "../chat-plus-calendar/PersistedProposalContext";
import { ErrorBlanket } from "./atoms/ErrorBlanket";

const useStyles = makeStyles({
  hideScrollbar: {
    scrollbarWidth: "none" /* Firefox */,
    msOverflowStyle: "none" /* IE 10+ */,
    "&::-webkit-scrollbar": {
      display: "none" /* Safari and Chrome */,
    },
  },
});

export const CardWrapper = ({
  children,
  onClose,
  header,
  footer,
  rsvpModule,
  hasFetchingError = false,
  shouldShowRSVP = false,
  changesCount = 0,
  onSave,
  refineInput,
}: {
  alsoSendUpdates?: boolean;
  children: React.ReactNode;
  onClose: () => void;
  onSave?: () => void;
  header: React.ReactNode;
  footer: React.ReactNode;
  rsvpModule?: React.ReactNode;
  hasFetchingError?: boolean;
  shouldShowRSVP?: boolean;
  changesCount?: number;
  refineInput?: React.ReactNode;
}) => {
  const { hideScrollbar } = useStyles();
  const { isDirty } = usePersistedProposal();

  useHotkeys("Escape", onClose, [isDirty], {
    scopes: ["prism"],
    description: "Close",
    preventDefault: true,
    enableOnContentEditable: true,
    enableOnFormTags: true,
  });

  useHotkeys(["meta+enter"], onSave ?? noop, [], {
    scopes: ["prism"],
    description: "Save event",
    enabled: !!onSave,
    preventDefault: true,
    enableOnFormTags: ["input", "textarea", "select"],
    enableOnContentEditable: true,
  });

  useHotkeys(["enter"], onSave ?? noop, [], {
    scopes: ["prism"],
    description: "Save event",
    enabled: !!onSave,
    preventDefault: true,
  });

  return (
    <div
      className="cw-z-40 cw-absolute cw-w-full cw-p-2 cw-h-full cw-flex cw-flex-col cw-gap-2"
      role="modal"
      aria-label="Event Details"
    >
      <div
        style={{
          boxShadow:
            "0px 0px 0px 1px rgba(28, 40, 64, 0.02), 0px 1px 4px 0px rgba(28, 40, 64, 0.12), 0px 0px 0px 1px rgba(28, 40, 64, 0.04)",
        }}
        className="cw-relative cw-flex cw-flex-col cw-bg-default cw-overflow-hidden cw-h-full cw-border cw-rounded-lg"
      >
        {hasFetchingError && (
          <ErrorBlanket
            hasError={hasFetchingError}
            onClose={onClose}
            message="Failed to fetch event details"
          />
        )}
        <div
          className={classNames(
            "cw-h-[30px]",
            "cw-px-3",
            "cw-bg-neutral",
            "cw-body-sm cw-text-12 cw-text-subtle",
            "cw-flex cw-justify-between cw-items-center",
            "cw-border-b-[0.5px] cw-border-solid cw-border-muted",
          )}
        >
          {header}
          <div className="cw-flex cw-items-center cw-justify-center cw-gap-2">
            {Boolean(changesCount) && (
              <>
                <div>{`${changesCount} ${pluralize("updates", changesCount)}`}</div>
                <div className="cw-border-solid cw-border-l cw-border-subtle cw-h-2 cw-w-[1px]" />
              </>
            )}
            <Tooltip title={changesCount > 0 ? "Close & discard" : "Close"}>
              <Button
                size="mini"
                aria-label="Collapse"
                variant="text"
                sentiment="neutral"
                startIcon={Close}
                onClick={onClose}
              />
            </Tooltip>
          </div>
        </div>
        <div
          className={`cw-flex cw-flex-col cw-flex-1 cw-overflow-y-scroll ${hideScrollbar} cw-gap-3`}
        >
          {children}
        </div>
        <div className="cw-bg-neutral cw-border-t-[0.5px] cw-border-solid cw-border-muted">
          {shouldShowRSVP && rsvpModule && <div className="cw-py-2 cw-px-3">{rsvpModule}</div>}
          <div
            className={classNames({
              "cw-border-t-[0.5px] cw-border-solid cw-border-muted": rsvpModule && shouldShowRSVP,
            })}
          >
            {footer}
          </div>
        </div>
      </div>
      {refineInput}
    </div>
  );
};
