import { ICalColorSet } from "@clockwise/client-commons/src/util/event-category-coloring";
import { bg_default, fg_onEmphasis } from "@clockwise/design-system/tokens";
import { DragOverlay, useDraggable } from "@dnd-kit/core";
import { animated, useSpring } from "@react-spring/web";
import classNames from "classnames";
import { motion, useMotionValue } from "framer-motion";
import { isNil, noop } from "lodash";
import { DateTime, Interval } from "luxon";
import React, {
  CSSProperties,
  HTMLAttributes,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  bg_calAnnotation,
  bg_calDeemphasis,
  bg_calOwn,
  bg_calOwn_Emphasis,
  border_calAnnotation,
  border_calDeemphasis,
  border_calOwn,
  border_calTransparent,
  fg_calDeemphasis,
  fg_calOwn,
} from "../../../constants/calendarColorTokens";
import { CalendarColorSet, getOwnCalendarColor } from "../../../util/calendar-coloring";
import { getFormattedDateRange } from "../../../util/date.util";
import { pulsingBlackOverlay } from "../../animations/pulsingBlackOverlay";
import { ICalPosition } from "../calendar-positioner/types";
import { CalendarDimensionsContext } from "./CalendarDimensionsContext";
import {
  MIN_DURATION_WHEN_RESIZING_IN_MINS,
  Transform,
  adjustForScrollWhileDragging,
  leashToProximity,
  restrictToCalendarBottomBoundariesResize,
  restrictToCalendarLeftRightBoundaries,
  restrictToCalendarTopBottomBoundariesDnD,
  snapTo15MinIncrements,
  snapTo15MinOffsets,
  snapToColumn,
  snapToLeftSideOfDropZone,
} from "./utils/DnDAndResizeModifiers";

export enum CalendarEventStatusEnum {
  "Feature" = "Feature",
  "Default" = "Default",
  "Possible" = "Possible",
  "Vestige" = "Vestige",
  "Prototype" = "Prototype",
  "Before" = "Before",
  "Unknown" = "Unknown",
}

export type CalendarEventStatus = keyof typeof CalendarEventStatusEnum;

export enum CalendarEventVariantEnum {
  "SingleLine" = "SingleLine",
  "MultiLine" = "MultiLine",
}

export type CalendarEventVariant = keyof typeof CalendarEventVariantEnum;

export enum CalendarEventSpacingEnum {
  "None" = "None",
  "Full" = "Full",
}

export type CalendarEventSpacing = keyof typeof CalendarEventSpacingEnum;

export type Props = HTMLAttributes<HTMLDivElement> & {
  active?: boolean;
  annotation?: string;
  badge?: ReactNode;
  calendarColorSet: CalendarColorSet;
  deemphasis?: boolean;
  transparent?: boolean;
  eventColorSet?: ICalColorSet;
  fade?: boolean;
  highlight?: boolean;
  onHighlightComplete?: () => void;
  spacing?: CalendarEventSpacing;
  status: CalendarEventStatus;
  stripes?: string[];
  subText?: string;
  text?: string;
  variant?: CalendarEventVariant;
  externalEventId?: string;
  columnIndex?: number;
  isResizing?: boolean;
  setIsResizing?: () => void;
  interval?: Interval;
  position?: ICalPosition;
  canDragAndDrop?: boolean;
  canResize?: boolean;
  canModify?: boolean;
  pulse?: boolean;
  calendarId?: string;
  canDragAcrossColumns?: boolean;
  isRecurring?: boolean;
  recurringEventId?: string | null;
  isHold?: boolean;
  isStale?: boolean;
};

type State = {
  active: boolean;
  annotation: string | undefined;
  backgroundColor: string;
  badge: ReactNode | undefined;
  borderColor: string;
  deemphasis: boolean;
  transparent: boolean;
  fade: boolean;
  foregroundColor: string;
  highlight: boolean;
  onHighlightComplete: () => void;
  railColor: string;
  spacing: CalendarEventSpacing;
  status: CalendarEventStatus;
  stripes: string[];
  subText: string | undefined;
  text: string;
  variant: CalendarEventVariant;
  pulse: boolean;
  isStale: boolean;
};

const defaultEventContextState = {
  active: false,
  annotation: "",
  backgroundColor: bg_calOwn,
  badge: undefined,
  borderColor: border_calOwn,
  deemphasis: false,
  transparent: false,
  fade: false,
  foregroundColor: fg_calOwn,
  highlight: false,
  onHighlightComplete: noop,
  railColor: bg_calOwn_Emphasis,
  spacing: CalendarEventSpacingEnum.Full,
  status: CalendarEventStatusEnum.Default,
  stripes: [],
  subText: "",
  text: "",
  variant: CalendarEventVariantEnum.SingleLine,
  pulse: false,
  isStale: false,
};

const CalendarEventContext = createContext<State>(defaultEventContextState);

const defaultCalendarColorSet: CalendarColorSet = getOwnCalendarColor();

// Change this to `true` if you want to see the different Drag Overlays and Resize Overlays
const SHOW_DEBUG_VISUALS = false;

// This function takes a `newStartTime` and contraints it to the calendar day of `currentStartTime`
export const getNewStartTimeConstraintedToSpecificDay = ({
  currentStartTime,
  duration,
  newStartTime,
}: {
  currentStartTime: DateTime;
  duration: number;
  newStartTime: DateTime;
}): DateTime => {
  const newStartTimeMin = currentStartTime.startOf("day");
  const newStatTimeMax = newStartTimeMin.plus({ day: 1 }).minus({ minutes: duration });

  const newStartTimeWithEventContraintedToDay =
    newStartTime < newStartTimeMin
      ? newStartTimeMin
      : newStartTime > newStatTimeMax
      ? newStatTimeMax
      : newStartTime;

  return newStartTimeWithEventContraintedToDay;
};

export const CalendarEvent = ({
  active,
  annotation,
  badge,
  calendarColorSet = defaultCalendarColorSet,
  deemphasis,
  transparent,
  eventColorSet,
  fade = false,
  highlight,
  onBlur,
  onMouseOver,
  onClick,
  onDoubleClick,
  onFocus,
  onHighlightComplete,
  spacing,
  status,
  stripes,
  subText,
  text,
  variant,
  isResizing,
  externalEventId,
  position,
  columnIndex,
  interval,
  canDragAndDrop,
  canResize,
  canModify,
  pulse,
  calendarId,
  canDragAcrossColumns,
  isRecurring,
  recurringEventId,
  isHold,
  isStale,
}: Props) => {
  const {
    calendarWeekHeightInPx,
    calendarWeekWidthInPx,
    numOfDaysShown,
    scrollContainerRef,
    scrollTopOfScrollContainerBeforeDragging,
  } = useContext(CalendarDimensionsContext);
  const inPx15minIncrement = calendarWeekHeightInPx / 24 / 4; // 24 hours in a day, 4 15min increments in an hour
  const eventFullWidthInPx = calendarWeekWidthInPx / numOfDaysShown;
  const height = position?.height || 0;
  const heightInPx = (height / 100) * calendarWeekHeightInPx;
  const width = position?.width || 0;
  const widthInPx = (width / 100) * calendarWeekWidthInPx;

  const supportsResize = externalEventId && interval && columnIndex !== undefined && canModify;

  const snappedHeight = useMotionValue(heightInPx);

  const { newDurationResize, currentStartTimeResize, newEndTimeResize } = useMemo(() => {
    const newDurationResize = Math.floor((snappedHeight.get() / inPx15minIncrement) * 15);
    const currentStartTimeResize = interval?.start;

    // Math note: `newEndTimeResize` does not account for accumulated rounding errors
    // But we need to see if there are any accumulated rounding errors
    const newEndTimeResize = currentStartTimeResize?.plus({
      minutes: newDurationResize,
    });

    // Math note: Sadly rounding error can accumlate after all the math is applied
    // We must double check that these times are to the correct 15min offset one last time
    const justMinutesOfNewEndTimeResize =
      newEndTimeResize?.diff(newEndTimeResize.startOf("hour"), "minutes").minutes || 0;
    const numberOf15MinIntervals = justMinutesOfNewEndTimeResize / 15;
    const roundedIntervals = Math.round(numberOf15MinIntervals);
    const roundedMinutes = roundedIntervals * 15;
    const smallRoundingAdjustmentInMinutes = roundedMinutes - justMinutesOfNewEndTimeResize;
    const newDurationWithRoundingCorrection = newDurationResize + smallRoundingAdjustmentInMinutes;
    const newDurationFinal = Math.max(
      newDurationWithRoundingCorrection,
      MIN_DURATION_WHEN_RESIZING_IN_MINS,
    );
    const newEndTimeFinal = currentStartTimeResize?.plus({
      minutes: newDurationFinal,
    });

    return {
      newDurationResize: newDurationFinal,
      currentStartTimeResize,
      newEndTimeResize: newEndTimeFinal,
    };
  }, [snappedHeight.get(), heightInPx, inPx15minIncrement, interval]);

  const {
    attributes: attributesResize,
    listeners: listenersResize,
    setNodeRef: setNodeRefResize,
    transform: transformResize,
    isDragging: isDraggingResize,
  } = useDraggable({
    id: `${externalEventId}-resize-handle`,
    disabled: !supportsResize || isStale,
    data: {
      externalEventId,
      interval,
      columnIndex,
      title: text,
      inPx15minIncrement,
      canModify,
      isDragAndDrop: false,
      isResize: true,
      newDuration: newDurationResize,
      isRecurring,
      recurringEventId,
      isHold,
      calendarId,
    },
  });

  const supportsDragAndDrop = externalEventId && interval && columnIndex !== undefined;

  const disableDragEvent =
    isDraggingResize ||
    isResizing || // disable dragging when resizing because it'll cause the event to move and grown all over the place
    !supportsDragAndDrop ||
    !canDragAndDrop ||
    isStale;
  const { attributes, listeners, setNodeRef, transform, isDragging, over } = useDraggable({
    id: `${externalEventId}-${calendarId}`,
    disabled: disableDragEvent,
    data: {
      externalEventId,
      interval,
      columnIndex,
      title: text,
      inPx15minIncrement,
      canModify,
      isDragAndDrop: true,
      isResize: false,
      isRecurring,
      recurringEventId,
      isHold,
      calendarId,
    },
  });

  const draggingWhilstCannotModify = isDragging && !canModify;
  const subColumnLeft = position?.subColumnLeft || 0;
  const subColumnLeftInPx = (subColumnLeft / 100) * calendarWeekWidthInPx;

  function snapToGridDnD(transform: Transform) {
    if (draggingWhilstCannotModify) {
      return leashToProximity({ transform, inPx15minIncrement, subColumnLeftInPx });
    }

    let retVal = {
      ...transform,
    };

    retVal = snapToColumn({
      transform: retVal,
      numOfDaysShown,
      over,
      columnIndex,
      eventFullWidthInPx,
    });
    retVal = snapTo15MinIncrements({ transform: retVal, inPx15minIncrement });
    retVal = snapToLeftSideOfDropZone({ transform: retVal, subColumnLeftInPx });
    retVal = restrictToCalendarLeftRightBoundaries({
      transform: retVal,
      columnIndex,
      eventFullWidthInPx,
      subColumnLeftInPx,
      canDragAcrossColumns,
      numOfDaysShown,
    });
    retVal = adjustForScrollWhileDragging({
      transform: retVal,
      scrollContainerRef,
      scrollTopOfScrollContainerBeforeDragging,
      inPx15minIncrement,
    });
    retVal = restrictToCalendarTopBottomBoundariesDnD({
      transform: retVal,
      position,
      calendarWeekHeightInPx,
      scrollContainerRef,
      scrollTopOfScrollContainerBeforeDragging,
    });

    return retVal;
  }

  function snapToGridResize(transform: Transform) {
    let retVal = {
      ...transform,
    };

    retVal = snapToColumn({
      transform: retVal,
      numOfDaysShown,
      over,
      columnIndex,
      eventFullWidthInPx,
    });
    retVal = snapTo15MinIncrements({ transform: retVal, inPx15minIncrement });
    retVal = snapTo15MinOffsets({
      transform: retVal,
      interval,
      snappedHeight,
      newEndTimeResize,
      inPx15minIncrement,
    });
    retVal = snapToLeftSideOfDropZone({ transform: retVal, subColumnLeftInPx });
    retVal = restrictToCalendarLeftRightBoundaries({
      transform: retVal,
      columnIndex,
      eventFullWidthInPx,
      subColumnLeftInPx,
      canDragAcrossColumns,
      numOfDaysShown,
    });
    retVal = restrictToCalendarBottomBoundariesResize({
      transform: retVal,
      position,
      calendarWeekHeightInPx,
      scrollContainerRef,
      scrollTopOfScrollContainerBeforeDragging,
    });

    return retVal;
  }

  const style = transform
    ? {
        transform: `translate3d(${snapToGridDnD(transform).x}px, ${
          snapToGridDnD(transform).y
        }px, 0)`,
      }
    : undefined;

  const rootRef = React.useRef<HTMLDivElement>(null);

  // Math note: if `MIN_DURATION_WHEN_RESIZING_IN_MINS` changes over time
  // `minHeight` will be calculated correctly with this verbose equation,
  // thus leave the verbose equation to capture the correct relationship
  const minHeight = (inPx15minIncrement * MIN_DURATION_WHEN_RESIZING_IN_MINS) / 15;

  useEffect(() => {
    if (!transformResize) return;

    const newHeight = heightInPx + snapToGridResize(transformResize).y;

    // Math note: dealing with `minHeight` enforcement in `snapToGridResize`
    // cause compounding rounding errors, and causes flickering,
    // thus we do it last here
    const newHeightRespectingMinHeight = Math.max(newHeight, minHeight);

    snappedHeight.set(newHeightRespectingMinHeight);
  }, [transformResize, snapToGridResize, snappedHeight]);
  useEffect(() => {
    if (isDraggingResize) return;

    // Reset the resize height
    snappedHeight.set(heightInPx);
  }, [isDraggingResize]);

  const dragAndDropNewTime = useMemo((): string => {
    if (!transform || !interval || isNil(columnIndex)) {
      return "";
    }

    const y = transform.y;
    const deltaTimeFromDragging = Math.ceil(y / inPx15minIncrement) * 15;

    const currentStartTime = interval.start;
    const duration = interval.end.diff(currentStartTime, "minutes").minutes;

    const currentScrollTop = scrollContainerRef?.current?.scrollTop || 0;
    const scrollDiff = currentScrollTop - scrollTopOfScrollContainerBeforeDragging;
    const intervalDiff = scrollDiff / inPx15minIncrement;

    // `properRoundingFunction` depends on `adjustForScrollWhileDragging`'s implementation
    const properRoundingFunction = intervalDiff < 0 ? Math.ceil : Math.floor;

    const deltaTimeFromScrolling = properRoundingFunction(scrollDiff / inPx15minIncrement) * 15;

    const newStartTime = currentStartTime.plus({
      minutes: deltaTimeFromDragging + deltaTimeFromScrolling,
    });

    const newStartTimeWithEventContraintedToDay = getNewStartTimeConstraintedToSpecificDay({
      newStartTime,
      duration,
      currentStartTime,
    });

    const newEndTime = newStartTimeWithEventContraintedToDay.plus({ minutes: duration }).toISO();
    const dateString = getFormattedDateRange({
      start: newStartTimeWithEventContraintedToDay.toISO(),
      end: newEndTime,
      options: { showRelativeDay: false },
    });

    return dateString;
  }, [transform, interval, columnIndex]);

  const resizeNewTime = useMemo(() => {
    if (!interval || !snappedHeight || !currentStartTimeResize || !newEndTimeResize) {
      return "";
    }

    const dateString = getFormattedDateRange({
      start: currentStartTimeResize.toISO(),
      end: newEndTimeResize.toISO(),
      options: { showRelativeDay: false },
    });

    return dateString;
  }, [
    interval,
    snappedHeight,
    transformResize, // Need this because `snappedmHeight` reference doesn't change
  ]);

  const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      rootRef.current?.click();
    }
  };

  const handleMouseOver: React.MouseEventHandler<HTMLDivElement> = (e) => {
    rootRef.current?.focus();
    onMouseOver?.(e);
  };

  const handleMouseOut = () => {
    rootRef.current?.blur();
  };

  let railColor = border_calDeemphasis;
  let backgroundColor = bg_calDeemphasis;
  let foregroundColor = fg_calDeemphasis;
  let borderColor = border_calDeemphasis;

  if (!deemphasis) {
    railColor = calendarColorSet.border;
    backgroundColor = eventColorSet?.background || calendarColorSet.bg;
    foregroundColor = eventColorSet?.foreground || calendarColorSet.fg;
    borderColor = eventColorSet?.foreground || calendarColorSet.border;
  }

  switch (status) {
    case CalendarEventStatusEnum.Feature:
      backgroundColor = borderColor;
      foregroundColor = fg_onEmphasis;
      break;
    case CalendarEventStatusEnum.Vestige:
    case CalendarEventStatusEnum.Before:
    case CalendarEventStatusEnum.Prototype:
    case CalendarEventStatusEnum.Unknown:
      backgroundColor = bg_default;
      break;
    default:
      break;
  }

  if (transparent) {
    foregroundColor = eventColorSet?.foreground || calendarColorSet.fg;
  }

  if (active) {
    backgroundColor = eventColorSet?.foreground || calendarColorSet.bg_Emphasis;
    foregroundColor = fg_onEmphasis;
  }

  const truncatedStripes = [...(stripes || defaultEventContextState.stripes)].slice(0, Stripes.max);
  const renderResizeHandle = canModify;

  // Dev Note: during D&D, this becomes the ghost (of the event)
  // in the case of `canModify` we want the ghost to show up on top still
  // in the case of `!canModify` we want the ghost to disappear
  // in the case that's it's `!isDragging` we want the background to show
  // otherwise in multical overlapping events will have the text bleed into each other
  const draggableIsTransparent = !canModify && isDragging;

  // the classnames `cw-group` and `group-hover:cw-opacity-100` allows the resize handle
  // to show up but has a side effect of having a 'hover highlight' which don't want
  // when the resize handle is hidden, thus only show it when `SHOW_DEBUG_VISUALS` is true
  const shouldRevealResizeHandleOnEventHover = SHOW_DEBUG_VISUALS;

  return (
    <CalendarEventContext.Provider
      value={{
        active: active || defaultEventContextState.active,
        annotation: annotation || defaultEventContextState.annotation,
        backgroundColor,
        badge: badge || defaultEventContextState.badge,
        borderColor,
        deemphasis: deemphasis || defaultEventContextState.deemphasis,
        transparent: transparent || defaultEventContextState.transparent,
        fade: fade || defaultEventContextState.fade,
        foregroundColor,
        highlight: highlight || defaultEventContextState.highlight,
        onHighlightComplete: onHighlightComplete || defaultEventContextState.onHighlightComplete,
        railColor,
        spacing: spacing || defaultEventContextState.spacing,
        status: status || defaultEventContextState.status,
        stripes: truncatedStripes,
        subText: subText || defaultEventContextState.subText,
        text: text || defaultEventContextState.text,
        variant: variant || defaultEventContextState.variant,
        pulse: pulse || false,
        isStale: isStale || false,
      }}
    >
      {!canDragAndDrop && (
        <div
          id={`calendar-event-before-dnd-and-resize-${externalEventId}-${calendarId}`}
          className="cw-h-full cw-w-full cw-bg-default cw-rounded "
        >
          <div
            className={classNames("cw-h-full cw-w-full cw-min-h-[10px]", {
              "cw-grayscale": deemphasis,
              "cw-opacity-50": fade,
            })}
            cw-id="calendar-event"
            data-event-title={(text || "undefined").split("❇️ ").slice(-1)[0]}
            ref={rootRef}
            onBlur={onBlur}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            onFocus={onFocus}
            onKeyUp={handleKeyUp}
            onMouseOut={handleMouseOut}
            onMouseOver={handleMouseOver}
          >
            <Container>
              <Annotation />
              <Badge />
              <Background>
                <Rail />
                <Stripes />
                <Layout>
                  <Text>{text}</Text>
                  <Text subText>{subText}</Text>
                </Layout>
              </Background>
            </Container>
          </div>
        </div>
      )}

      {canDragAndDrop && (
        <div
          id={`draggable-${externalEventId}-${calendarId}`}
          className={classNames("cw-h-full cw-rounded", {
            "cw-bg-transparent": draggableIsTransparent,
            "cw-bg-default": !draggableIsTransparent,
            // "cw-h-full": !isDraggingResize, // Allow resize logic to take over when `isDraggingResize` `true`
            // We must use the `resize-overlay` to avoid position bugs with the resize handle
            // Thus make this `cw-w-0` while `isDraggingResize` is `true`
            "cw-opacity-0": isDraggingResize,
            "cw-w-full": !isDraggingResize,
            "cw-w-0": isDraggingResize,
            "cw-group": shouldRevealResizeHandleOnEventHover,
          })}
          ref={setNodeRef}
          {...listeners}
        >
          <div
            className={classNames("cw-h-full cw-w-full cw-min-h-[10px]", {
              "cw-grayscale": deemphasis,
              "cw-opacity-50": (fade || isDragging) && !draggingWhilstCannotModify,
              "cw-opacity-0": draggingWhilstCannotModify,
            })}
            cw-id="calendar-event"
            data-event-title={(text || "undefined").split("❇️ ").slice(-1)[0]}
            ref={rootRef}
            onBlur={onBlur}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            onFocus={onFocus}
            onKeyUp={handleKeyUp}
            onMouseOut={handleMouseOut}
            onMouseOver={handleMouseOver}
          >
            <Container>
              <Annotation />
              <Badge />
              <Background>
                <Rail />
                <Stripes />
                <Layout>
                  <Text>{text}</Text>
                  <Text subText>{subText}</Text>
                  {SHOW_DEBUG_VISUALS && <Text subText>Draggable</Text>}
                </Layout>
                {renderResizeHandle && (
                  <div
                    className={classNames("cw-opacity-0", {
                      "group-hover:cw-opacity-100": shouldRevealResizeHandleOnEventHover,
                    })}
                    style={{
                      backgroundColor: SHOW_DEBUG_VISUALS ? "lavender" : "transparent",
                      height: 4,
                      width: "100%",
                      cursor: "ns-resize",
                      textAlign: "center",
                      pointerEvents: canResize ? undefined : "none",
                      left: 0,
                    }}
                    ref={setNodeRefResize}
                    {...listenersResize}
                    {...{
                      ...attributesResize,
                      // UX Note: do not let this handle be tabbable or highlight-able
                      tabIndex: -1,
                      role: undefined,
                    }}
                  ></div>
                )}
              </Background>
            </Container>
          </div>
        </div>
      )}

      {isDraggingResize && (
        <motion.div
          id={`resize-overlay-${externalEventId}`}
          className={classNames("cw-w-full cw-rounded cw-bg-default cw-shadow-lg", {
            "cw-bg-default": canModify,
            "cw-h-full": !isDraggingResize, // Allow resize logic to take over when `isDraggingResize` true
          })}
          style={{
            height: snappedHeight,
            cursor: isResizing ? "ns-resize" : "",
            zIndex: Number.MAX_SAFE_INTEGER,
          }}
        >
          <div
            className={classNames("cw-h-full cw-w-full cw-min-h-[10px]", {})}
            cw-id="calendar-event"
            data-event-title={(text || "undefined").split("❇️ ").slice(-1)[0]}
          >
            <Container>
              <Annotation />
              <Badge />
              <Background>
                <Rail />
                <Stripes />
                <Layout>
                  <Text>{text}</Text>
                  <Text subText>{isDraggingResize ? resizeNewTime : subText}</Text>
                  {SHOW_DEBUG_VISUALS && <Text subText>Resize Overlay</Text>}
                </Layout>
              </Background>
            </Container>
          </div>
        </motion.div>
      )}

      {canDragAndDrop && isDragging && (
        <DragOverlay modifiers={[]} style={style} {...attributes}>
          <div
            id={`drag-overlay-${externalEventId}-${calendarId}`}
            style={{
              cursor: isResizing ? "ns-resize" : "",
              width: isDragging ? eventFullWidthInPx : widthInPx,
            }}
            onClick={!isResizing ? onClick : undefined}
            onDoubleClick={!isResizing ? onDoubleClick : undefined}
          >
            <motion.div
              style={{
                height: heightInPx,
                cursor: isResizing ? "ns-resize" : "",
              }}
            >
              <div className="cw-h-full cw-w-full cw-bg-default cw-rounded ">
                <div
                  className={classNames("cw-h-full cw-w-full cw-min-h-[10px]", {
                    "cw-grayscale": deemphasis,
                    "cw-opacity-50": fade || draggingWhilstCannotModify,
                  })}
                  cw-id="calendar-event"
                  data-event-title={(text || "undefined").split("❇️ ").slice(-1)[0]}
                  ref={rootRef}
                  onBlur={onBlur}
                  onClick={onClick}
                  onFocus={onFocus}
                  onKeyUp={handleKeyUp}
                  onMouseOut={handleMouseOut}
                  onMouseOver={handleMouseOver}
                >
                  <div className="cw-h-full cw-w-full cw-bg-default cw-rounded cw-relative cw-shadow-lg">
                    <div
                      className={classNames("cw-h-full cw-w-full cw-min-h-[10px]", {
                        "cw-grayscale": deemphasis,
                        "cw-opacity-50": fade,
                      })}
                      cw-id="calendar-event"
                      ref={rootRef}
                      onBlur={onBlur}
                      onClick={onClick}
                      onDoubleClick={onDoubleClick}
                      onFocus={onFocus}
                      onKeyUp={handleKeyUp}
                      onMouseOut={handleMouseOut}
                      onMouseOver={handleMouseOver}
                    >
                      <Container>
                        <Annotation />
                        <Badge />
                        <Background>
                          <Rail />
                          <Stripes />
                          <Layout>
                            <Text>{text}</Text>
                            <Text subText>
                              {canModify && isDragging ? dragAndDropNewTime : subText}
                            </Text>
                            {SHOW_DEBUG_VISUALS && <Text subText>Drag Overlay</Text>}
                          </Layout>
                        </Background>
                      </Container>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </DragOverlay>
      )}
    </CalendarEventContext.Provider>
  );
};

const Container = ({ children }: { children: ReactNode }) => {
  const { highlight, borderColor, onHighlightComplete } = useContext(CalendarEventContext);
  const [animationState, setAnimationState] = useState<"in" | "out" | "none">("none");

  useEffect(() => {
    if (highlight) {
      setAnimationState("in");
    }
  }, [highlight]);

  const animationInConfig = {
    from: { borderColor: "transparent" },
    to: { borderColor },
    config: { duration: 1000 },
    delay: 50,
    onResolve: () => setAnimationState("out"),
  };

  const animationOutConfig = {
    from: { borderColor },
    to: { borderColor: "transparent" },
    config: { duration: 1000 },
    delay: 4000,
    onResolve: () => {
      setAnimationState("none");
      onHighlightComplete();
    },
  };

  const animationNoneConfig = {
    from: { borderColor: "transparent" },
    to: { borderColor: "transparent" },
  };

  let animationConfig = null;
  switch (animationState) {
    case "in":
      animationConfig = animationInConfig;
      break;
    case "out":
      animationConfig = animationOutConfig;
      break;
    default:
      animationConfig = animationNoneConfig;
      break;
  }

  const style = useSpring(animationConfig);

  return (
    <animated.div
      className={classNames("cw-h-full cw-w-full cw-relative", "cw-border-solid cw-border")}
      style={style}
    >
      {children}
    </animated.div>
  );
};

const Annotation = () => {
  const { annotation } = useContext(CalendarEventContext);
  if (!annotation) return null;

  const style: CSSProperties = {
    backgroundColor: bg_calAnnotation,
    borderColor: border_calAnnotation,
  };

  return (
    <div
      className={classNames(
        "cw-absolute cw-top-[-24px]",
        "cw-leading-4 cw-overflow-hidden cw-text-clip cw-whitespace-nowrap",
        "cw-z-[41]",
        "cw-body-base",
        "cw-rounded cw-border-[1px] cw-border-solid",
        "cw-px-1 cw-py-[2px]",
        "cw-text-[12px] cw-leading-[13px]",
        "cw-font-medium",
        "cw-no-underline",
      )}
      style={style}
    >
      {annotation}
    </div>
  );
};

const Badge = () => {
  const { badge, backgroundColor, borderColor } = useContext(CalendarEventContext);

  if (!badge) return null;

  return (
    <div className={classNames("cw-absolute cw-top-[-10px] cw-right-[-10px]", "cw-z-40")}>
      <div
        className={classNames(
          "cw-h-[24px] cw-w-[24px]",
          "cw-flex cw-items-center cw-justify-center",
          "cw-rounded-full cw-border-1 cw-border-solid",
          "cw-overflow-hidden",
        )}
        style={{
          backgroundColor,
          borderColor,
        }}
      >
        {badge}
      </div>
    </div>
  );
};

const Background = ({ children }: { children: ReactNode }) => {
  const {
    backgroundColor,
    borderColor,
    status,
    spacing,
    active: isActive,
    pulse,
    transparent,
    isStale,
  } = useContext(CalendarEventContext);

  // border should be solid & transparent when set to none to avoid layout shifts
  const style: CSSProperties = {
    backgroundColor: backgroundColor,
    borderColor: "transparent",
    borderStyle: "solid",
  };

  const isPrototpype = status === CalendarEventStatusEnum.Prototype;
  const isVestige = status === CalendarEventStatusEnum.Vestige;
  const isBefore = status === CalendarEventStatusEnum.Before;
  const isUnknown = status === CalendarEventStatusEnum.Unknown;

  if (isBefore || ((isVestige || isUnknown) && !isActive)) {
    style.borderStyle = "dashed";
  }

  if (isPrototpype || isVestige || isBefore || isUnknown || isActive) {
    style.borderColor = borderColor;
  }

  if (transparent && !isActive) {
    style.backgroundColor = "#FFFFFF";
    style.borderColor = border_calTransparent;
  }

  return (
    <div
      className={classNames(
        "cw-h-full cw-w-full",
        "cw-body-base",
        "cw-rounded cw-border-[1px]",
        "cw-cursor-pointer",
        "cw-box-border",
        "cw-border-l-0",
        "cw-pl-[8px]",
        {
          "cw-py-1": spacing === CalendarEventSpacingEnum.Full,
          "cw-py-0": spacing === CalendarEventSpacingEnum.None,
          [pulsingBlackOverlay]: pulse,
          "cw-opacity-70": isStale,
          "cw-cursor-wait": isStale,
        },
        isActive ? "cw-drop-shadow" : "cw-drop-shadow-none",
      )}
      style={style}
    >
      {children}
    </div>
  );
};

const Layout = ({ children }: { children: ReactNode }) => {
  const { variant, stripes } = useContext(CalendarEventContext);

  const isCompact = variant === CalendarEventVariantEnum.SingleLine;

  return (
    <div
      className="cw-flex cw-space-x-1 cw-h-full cw-w-full cw-overflow-hidden"
      style={{
        paddingLeft: stripes.length * Stripes.fullWidth,
        minHeight: 12,
        marginTop: isCompact ? -1 : 2,
      }}
    >
      <div
        className={classNames(
          "cw-flex",
          variant === CalendarEventVariantEnum.SingleLine
            ? "cw-flex-row cw-items-start"
            : "cw-flex-col",
          variant === CalendarEventVariantEnum.SingleLine ? "cw-space-x-1" : "cw-space-y-0",
          variant === CalendarEventVariantEnum.SingleLine
            ? "cw-whitespace-nowrap"
            : "cw-whitespace-normal",
        )}
      >
        {children}
      </div>
    </div>
  );
};

const Rail = () => {
  const { railColor, status, active: isActive, stripes, transparent } = useContext(
    CalendarEventContext,
  );

  const style: CSSProperties = {
    backgroundColor: railColor,
    borderColor: railColor,
  };

  const isPossible = status === CalendarEventStatusEnum.Possible;
  const isPrototype = status === CalendarEventStatusEnum.Prototype;
  const isVestige = status === CalendarEventStatusEnum.Vestige;
  const isBefore = status === CalendarEventStatusEnum.Before;
  const isUnknown = status === CalendarEventStatusEnum.Unknown;
  const hasStripes = stripes.length > 0;

  const hasOuterBorder = isActive || isPrototype || isVestige || isBefore || isUnknown;

  if (isPossible) {
    style.backgroundColor = undefined;
    style.background = `repeating-linear-gradient( 45deg, #FFFFFF, #FFFFFF 1px, ${railColor} 0px, ${railColor} 4px)`;
    style.borderColor = railColor;
  }
  if (isVestige) {
    style.borderColor = transparent ? border_calTransparent : railColor;
    style.backgroundColor = "transparent";
  }
  if (isBefore) {
    style.borderColor = transparent ? border_calTransparent : railColor;
    style.backgroundColor = "transparent";
  }
  if (isUnknown) {
    style.borderColor = transparent ? border_calTransparent : railColor;
    style.backgroundColor = "transparent";
  }

  return (
    <div
      className={classNames(
        "cw-absolute cw-top-[-1px] cw-bottom-[-1px] cw-left-0",
        "cw-z-[25]",
        "cw-w-[4px]",
        "cw-border cw-border-solid cw-rounded-l",
        { "cw-border-r-0": !hasStripes && (isUnknown || isVestige || transparent) },
        { "cw-border-dashed": !isActive && !isPossible },
        { "cw-border-solid": isActive || isPossible },
        { "cw-border-l": hasOuterBorder },
      )}
      style={style}
    >
      {/* rail - intentionally empty */}
    </div>
  );
};

const Stripes = () => {
  const { stripes } = useContext(CalendarEventContext);

  if (!stripes) return null;

  return (
    <div
      className={classNames("cw-absolute cw-top-[-1px] cw-bottom-[-1px] cw-left-[4px]")}
      style={{
        width: stripes.length * Stripes.fullWidth,
      }}
    >
      <div className="cw-flex cw-h-full cw-w-full">
        {stripes.map((color) => (
          <div
            key={`color-${color}`}
            className="cw-h-full"
            style={{
              backgroundColor: color,
              width: Stripes.width,
              marginLeft: Stripes.marginLeft,
            }}
          />
        ))}
      </div>
    </div>
  );
};
Stripes.width = 4;
Stripes.marginLeft = 1;
Stripes.max = 3;
Stripes.fullWidth = Stripes.width + Stripes.marginLeft;

const Text = ({ children, subText = false }: { children?: ReactNode; subText?: boolean }) => {
  const { foregroundColor, status } = useContext(CalendarEventContext);
  if (!children) return null;

  const color = foregroundColor;
  const isVestige = status === CalendarEventStatusEnum.Vestige;

  return (
    <div
      className={classNames(
        "cw-body-base",
        "cw-leading-[13px]",
        subText ? "cw-text-[11px]" : "cw-text-[12px]",
        subText ? "" : "cw-font-medium",
        isVestige ? "cw-line-through" : "",
      )}
      style={{ color }}
    >
      {children}
    </div>
  );
};
