import { FastCrawlErrorCard } from "#webapp/src/components/fast-crawl-error-card";
import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
import { Loader } from "@clockwise/design-system/src/components/Loader";
import React, { useEffect } from "react";
import { useGetOrgId } from "../../hooks/useGetOrgId";
import { track, TrackingEvents } from "../../util/analytics.util";
import { canAccessExperiments } from "../../util/auth.util";
import { useFullStory } from "../../util/fullstory";
import { useOrgCrawlFailure } from "./hooks/useOrgCrawlFailure";
import { WebOnboardingStepProvider } from "./hooks/useWebOnboardingSteps";
import { ErrorWrapper } from "./shared-components";
import { WebOnboardingAssetPreloader } from "./WebOnboardingAssetPreloader";
import { WebOnboardingSteps } from "./WebOnboardingSteps";

export const WebOnboardingRenderer = () => {
  const { loading: crawlLoading, orgCrawlFailure, failureView } = useOrgCrawlFailure();
  const { org, loading } = useGetOrgId();
  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const { identify } = useFullStory();

  useEffect(() => {
    if (isMobile) {
      track(TrackingEvents.WEB_ONBOARDING.IS_MOBILE);
    }
  }, [isMobile]);

  useEffect(() => {
    if (orgCrawlFailure) {
      track(TrackingEvents.WEB_ONBOARDING.ERROR, { message: "org crawl failure" });
    }
  }, [orgCrawlFailure]);

  useEffect(() => {
    identify();
  }, [identify]);

  if (loading || crawlLoading || !org) {
    return (
      <div className="cw-w-full cw-flex cw-items-center cw-justify-center cw-h-screen">
        <Loader size="xl" sentiment="positive" />
      </div>
    );
  }
  if (orgCrawlFailure && failureView) {
    return (
      <ErrorWrapper>
        <FastCrawlErrorCard view={failureView} orgId={org.id} />
      </ErrorWrapper>
    );
  }

  const canSkipOnboarding =
    canAccessExperiments() &&
    (getEnvironment() === "development" || getEnvironment() === "staging");

  return (
    <WebOnboardingStepProvider>
      <WebOnboardingAssetPreloader />
      <WebOnboardingSteps canSkipOnboarding={canSkipOnboarding} orgId={org.id} />
    </WebOnboardingStepProvider>
  );
};
