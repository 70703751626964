import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import { OverflowAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatarStack";
import { Skeleton } from "@material-ui/lab";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { PropsWithChildren, default as React } from "react";
import { EventDetailsAttendees } from "../hooks/useEventPopoverDetails";
import { getStringListOfAttendees } from "../utils/getStringListOfAttendees";

export const ClickableContainer = ({
  readOnly,
  onClick,
  children,
}: PropsWithChildren<{ readOnly?: boolean; onClick: () => void }>) => {
  return (
    <div
      className={classNames(
        "cw-px-1.5 cw-py-[5px]",
        "cw-flex cw-flex-col cw-space-y-3",
        "cw-rounded-lg",
        { "cw-cursor-pointer hover:cw-bg-default-hover": !readOnly },
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export const EventDetailsHeader = ({
  attendees,
  calendarId,
  loading,
  title,
  isLocked,
  categoryColor,
  eventTimeString,
}: {
  attendees: EventDetailsAttendees;
  calendarId: string;
  loading: boolean;
  title: string;
  isLocked: boolean;
  categoryColor?: string;
  eventTimeString?: string;
}) => {
  const showAttendees = !loading && !isEmpty(attendees);

  return (
    <div className="cw-flex cw-items-center cw-justify-between cw-overflow-hidden cw-whitespace-nowrap cw-text-ellipsis cw-w-full">
      <div className="cw-flex cw-flex-col cw-space-y-2 cw-max-w-full">
        <div className="cw-flex cw-items-center cw-gap-1.5 cw-h-10">
          {categoryColor ? (
            <div
              style={{ backgroundColor: categoryColor }}
              className="cw-w-1 cw-rounded-full cw-h-full"
            ></div>
          ) : (
            ""
          )}
          <div className="cw-max-w-full">
            <div className="cw-text-default cw-body-base cw-text-14 cw-font-semibold cw-text-ellipsis cw-whitespace-nowrap cw-overflow-hidden">
              {title}
            </div>
            <div className="cw-body-sm cw-text-12 cw-text-subtle">{eventTimeString}</div>
          </div>
        </div>
        {showAttendees && (
          <HeaderAvatars
            attendees={attendees}
            calendarId={calendarId}
            loading={loading}
            isLocked={isLocked}
          />
        )}
      </div>
    </div>
  );
};

const HeaderAvatars = ({
  attendees,
  calendarId,
  loading,
  isLocked,
}: {
  attendees: EventDetailsAttendees;
  calendarId: string;
  loading: boolean;
  isLocked: boolean;
}) => {
  const MAX_ATTENDEES_SHOWN = 8;

  // If there are 6 show attendees, if 2+ over 5, show "+number" avatar
  const numberAttendeesToShow =
    attendees.length === MAX_ATTENDEES_SHOWN + 1 ? MAX_ATTENDEES_SHOWN + 1 : MAX_ATTENDEES_SHOWN;

  const nonShownAttendeeCount = attendees.length - numberAttendeesToShow;
  const showAdditionalAvatar = nonShownAttendeeCount > 0;
  return (
    <>
      {loading && !isLocked && (
        <div className="cw-flex-col">
          <div className="cw-flex cw-gap-x-2 cw-w-full cw-my-1">
            <Skeleton variant="circle" width={24} height={24} />
            <Skeleton variant="circle" width={24} height={24} />
          </div>
          <div className="cw-flex cw-py-1">
            <Skeleton variant="circle" width={16} height={16} className="cw-mr-2" />
            <Skeleton variant="rect" width={200} height={16} className="cw-rounded-md" />
          </div>
          <div className="cw-flex cw-py-1">
            <Skeleton variant="circle" width={16} height={16} className="cw-mr-2" />
            <Skeleton variant="rect" width={200} height={16} className="cw-rounded-md" />
          </div>
          <div className="cw-flex cw-py-1">
            <Skeleton variant="circle" width={16} height={16} className="cw-mr-2" />
            <Skeleton variant="rect" width={200} height={32} className="cw-rounded-md" />
          </div>
        </div>
      )}
      <div className="cw-flex cw-gap-x-1 cw-w-full cw-pb-[2px]">
        {attendees.slice(0, numberAttendeesToShow).map((attendee) => {
          const profileNoName = {
            externalImageUrl: attendee.person.externalImageUrl,
          };
          return (
            <AttendeeAvatar
              key={attendee.person.id}
              isOrganizer={attendee.isOrganizer ?? false}
              profile={profileNoName}
              calendarId={attendee.person.email}
              iconType={attendee.responseStatus}
              size="medium"
              displayName={attendee.person.displayName}
            />
          );
        })}
        {showAdditionalAvatar && (
          <OverflowAvatar
            size="medium"
            count={nonShownAttendeeCount}
            overflowTooltipContent={getStringListOfAttendees(
              attendees.slice(numberAttendeesToShow),
              calendarId,
            )}
          />
        )}
      </div>
    </>
  );
};
