import { useFeatureFlag } from "#webapp/src/launch-darkly/useLaunchDarkly";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { noop } from "lodash";
import { DateTime, Interval } from "luxon";
import React, { useEffect } from "react";
import {
  RefineProposalDocument,
  RefineProposalMutation,
} from "../../chat-plus-calendar/apollo/__generated__/CreateProposal.v2.generated";
import {
  Attendee,
  CurrentProposal,
  useUpdateCurrentProposal,
} from "../../chat-plus-calendar/CurrentProposalContext";
import { usePersistedProposal } from "../../chat-plus-calendar/PersistedProposalContext";
import { RefineInput } from "../../chat/ai-chat/ChatInput/RefineInput";

const getTimeFromDiff = (
  diff: Exclude<RefineProposalMutation["refineProposal"], null>["diffBlocks"][0]["diffs"][0],
) => {
  let startTime, endTime: DateTime | undefined;
  if (diff.__typename === "AddDiff" || diff.__typename === "ModifyDiff") {
    const time = diff.time;
    if (time?.__typename === "DateRange") {
      const interval = Interval.fromISO(time.dateRange);
      startTime = interval.start;
      endTime = interval.end;
    } else if (time?.__typename === "DateTimeRange") {
      const interval = Interval.fromISO(time.timeRange);
      startTime = interval.start;
      endTime = interval.end;
    }
  }
  return { startTime, endTime };
};

const getAttendeesFromDiff = (
  diff: Exclude<RefineProposalMutation["refineProposal"], null>["diffBlocks"][0]["diffs"][0],
): Attendee[] => {
  let attendees: Attendee[] = [];

  if (diff.__typename === "AddDiff") {
    attendees = diff.attendees.map((attendee) => ({
      id: attendee.id,
      email: attendee.email,
      responseStatus: attendee.responseStatus,
      isOptional: attendee.isOptional,
      isOrganizer: attendee.isOrganizer,
      person: attendee.person,
      user: attendee.member?.user ?? null,
    }));
  } else if (diff.__typename === "ModifyDiff") {
    attendees = diff.attendees.map((attendee) => ({
      id: attendee.id,
      email: attendee.email,
      responseStatus: attendee.responseStatus,
      isOptional: attendee.isOptional,
      isOrganizer: attendee.isOrganizer,
      person: attendee.person,
      user: attendee.member?.user ?? null,
    }));
  }

  return attendees;
};

const mapProposalResponsetoCurrentProposal = (
  proposal: RefineProposalMutation["refineProposal"],
): Partial<CurrentProposal> => {
  const diff = proposal?.diffBlocks[0].diffs[0];

  if (!diff) return {};

  const { startTime, endTime } = getTimeFromDiff(diff);
  const attendees = getAttendeesFromDiff(diff);

  return {
    title: diff.title,
    description: diff.description,
    startTime,
    endTime,
    attendees,
    initialAttendeeIds: attendees.map((attendee) => attendee.person.email),
    proposalId: proposal?.id,
  } as CurrentProposal;
};

export const ProposalRefine = () => {
  const { id: proposalId } = usePersistedProposal();
  const [refineProposal, { data, loading }] = useGatewayMutation(RefineProposalDocument);
  const { updateFromRefinement } = useUpdateCurrentProposal();
  const [refineProposalEnabled] = useFeatureFlag("RefineProposal");

  // Reflect the proposal changes in the UI
  useEffect(() => {
    if (data?.refineProposal) {
      const updatedProposal = mapProposalResponsetoCurrentProposal(data.refineProposal);
      updateFromRefinement(updatedProposal);
    }
  }, [data]);

  const handleRefineProposal = async (userMessage: string) => {
    if (!proposalId) {
      return;
    }

    await refineProposal({
      variables: {
        proposalId,
        userMessage,
      },
      refetchQueries: ["CurrentPersistedProposal"],
    });
  };

  if (!refineProposalEnabled) {
    return null;
  }

  return <RefineInput onCancel={noop} onSubmit={handleRefineProposal} loading={loading} />;
};
