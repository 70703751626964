import { Loader } from "@clockwise/design-system/src/components/Loader";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FlowStateProvider } from "../../hooks/useFlowState";
import { useOnboarding } from "../../hooks/useOnboarding/useOnboarding";
import { WebAppOnboardingCompleteRenderer } from "../web-app-onboarding-complete";
import { WebOnboardingRenderer } from "../web-onboarding";

function WebOnboardingComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, orgId, shouldRenderOnboarding, shouldRedirectApp } = useOnboarding();

  useEffect(() => {
    if (shouldRedirectApp) {
      // Redirect to home if onboarding is already complete.
      navigate("/app", { replace: true });
    }
  }, [navigate, shouldRenderOnboarding, shouldRedirectApp]);

  if (loading) {
    return (
      <div className="cw-w-full cw-flex cw-items-center cw-justify-center cw-h-screen">
        <Loader size="xl" sentiment="positive" />
      </div>
    );
  }

  if (shouldRenderOnboarding) {
    return <WebOnboardingRenderer />;
  }

  if (location.pathname === "/app/onboarding" && orgId) {
    return <WebAppOnboardingCompleteRenderer orgRelayId={orgId} />;
  }

  return null;
}

export const WebOnboarding = () => (
  <FlowStateProvider>
    <WebOnboardingComponent />
  </FlowStateProvider>
);
