import React from "react";
import { useFeatureFlag } from "../../launch-darkly";
import { AIBannerMessages } from "../chat/ai-chat/AIBannerMessages";
import { AIDeepLinkSync } from "../chat/ai-chat/AIDeeplinkSync";
import { ChatPlusCalendarDateSync } from "../chat/ai-chat/ChatPlusCalendarDateSync";
import { AIErrorProvider } from "../chat/ai-chat/hooks/AIErrorContext";
import { NUXEducationModalWrapper } from "../nux-education-modals/NUXEducationModalWrapper";
import { PMFSurveyWrapper } from "../pmf-survey/PMFSurveyWrapper";
import { useShowPMFSurvey } from "../pmf-survey/hooks/useShowPMFSurvey";
import { PrismEducationModal } from "../prism-education-modal/PrismEducationModal";
import AnimatedAIChat from "./AnimatedAIChat";
import { AnimatedCalendar } from "./AnimatedCalendar";
import { CommandBar } from "./CommandBar";
import Context from "./Context";

export const AIChatPlusCalendar = () => {
  const [enableCommandBar] = useFeatureFlag("CommandBar");
  const [showDirectManipulationEducationModal] = useFeatureFlag("PrismEducationModal");

  const showPMFSurvey = useShowPMFSurvey();

  return (
    <AIErrorProvider>
      <Context>
        <NUXEducationModalWrapper />
        <AIDeepLinkSync />
        <ChatPlusCalendarDateSync />
        {enableCommandBar && <CommandBar />}
        <AIBannerMessages>
          <div
            cw-id="chatPlusCalendar"
            className="cw-relative cw-w-full cw-h-full cw-overflow-x-scroll"
          >
            <AnimatedAIChat />
            <AnimatedCalendar />
          </div>
        </AIBannerMessages>
        {showDirectManipulationEducationModal && <PrismEducationModal />}
        {showPMFSurvey && <PMFSurveyWrapper />}
      </Context>
    </AIErrorProvider>
  );
};

export default AIChatPlusCalendar;
