import { IOrg } from "#webapp/src/__schema__";
import { useQuery } from "@apollo/client";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { logger } from "../../util/logger.util";
import { getCurrentOrg } from "../../util/org.util";
import { SlackSettings } from "../slack-settings";
import { SlackIntegrationQueryDocument } from "./graphql/__generated__/SlackIntegration.generated";

const SlackIntegration = () => {
  const { loading, data, error, refetch } = useQuery(SlackIntegrationQueryDocument);

  if (error) {
    logger.error(error?.message);
    return <SlackIntegrationError />;
  }

  if (!data || loading) {
    return <SlackIntegrationSkeleton />;
  }

  const viewer = data.viewer;
  const org = getCurrentOrg(viewer) as IOrg;

  if (!org) {
    logger.error("Failed to fetch org.");
    return <SlackIntegrationError />;
  }

  return <SlackSettings org={org} refetch={refetch} />;
};

const SlackIntegrationError = () => (
  <div className="cw-text-warning">Unable to load Slack settings.</div>
);

const SlackIntegrationSkeleton = () => <Skeleton variant="rect" width="160px" height="45px" />;

export default SlackIntegration;
