import { greys } from "@clockwise/web-commons/src/styles/color.styles";
import spacing from "@clockwise/web-commons/src/styles/spacing.styles";

export const intro = {
  color: greys.slate,
  fontSize: 14,
  lineHeight: 1.5,
} as const;

export const addSlack = {
  paddingTop: 10,
} as const;

export const slackAccounts = {
  width: "100%",
  marginTop: spacing.std,
  flexGrow: 1,
} as const;

export const needsUpdate = {
  marginRight: 5,
} as const;
