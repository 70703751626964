import { useCalendarDimensions } from "#webapp/src/hooks/useCalendarDimensions";
import { useScrollTopOfScrollContainerBeforeDragging } from "#webapp/src/hooks/useScrollTopOfScrollContainerBeforeDragging";
import { FlexRange } from "@clockwise/schema/v2";
import {
  CalendarDensity,
  CalendarPositioner,
} from "@clockwise/web-commons/src/components/calendar";
import { CalendarAvailability } from "@clockwise/web-commons/src/components/calendar/calendar-availability/CalendarAvailability";
import { CalendarConflicts } from "@clockwise/web-commons/src/components/calendar/calendar-conflicts/CalendarConflicts";
import { CalendarDimensionsContext } from "@clockwise/web-commons/src/components/calendar/calendar-event/CalendarDimensionsContext";
import { CalendarTimeDial } from "@clockwise/web-commons/src/components/calendar/calendar-time-dial/CalendarTimeDial";
import {
  EventType,
  useReadActiveEvent,
  useUpdateActiveEvent,
} from "@clockwise/web-commons/src/util/ActiveEventContext";
import { useReadCalendar } from "@clockwise/web-commons/src/util/CalendarContext";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { showWeeksWorkHourBoundaries } from "@clockwise/web-commons/src/util/local-storage";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { useDraggable, useDroppable } from "@dnd-kit/core";
import { animated, useSpring } from "@react-spring/web";
import classNames from "classnames";
import { compact, isEqual } from "lodash";
import { DateTime, Interval } from "luxon";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useReadLocalStorage } from "usehooks-ts";
import { useUserConfrencingTypes } from "../../../hooks/useUserConfrencingTypes";
import { useFeatureFlag } from "../../../launch-darkly";
import { IReduxState } from "../../../state/reducers/root.reducer";
import {
  useCurrentProposal,
  useUpdateCurrentProposal,
} from "../../chat-plus-calendar/CurrentProposalContext";
import { useDefragProposal } from "../../chat-plus-calendar/DefragProposalContext";
import { useUpdateActiveEventDiff } from "../../chat-plus-calendar/util/ActiveDiffContext";
import { ConflictClusterWithConversationId } from "../../chat-plus-calendar/util/parseConflictClustersGQL";
import { useAIMessageContext } from "../../chat/ai-chat/hooks/AIMessageContext";
import { useCollaborators } from "../../chat/ai-chat/hooks/useCollaborators";
import { useUserProfile } from "../../hooks/useUserProfile";
import { RescheduleConfirmationWrapper } from "../../reschedule-confirmation-modal";
import { CalendarWeekDNDContext } from "../../root/DndContext";
import { usePlannerContext } from "../Context";
import { ConflictPopover } from "../calendar-popover/ConflictPopover";
import { useAvailablities } from "../hooks/useAvailablities";
import { OOOEventsUnderlay } from "../ooo-events/OOOEventsUnderlay";
import DateTimeMarker from "../planner-datetime-marker/DateTimeMarker";
import { PlannerEvents } from "../planner-event";
import { PlannerHead } from "../planner-head/PlannerHead";
import PlannerMask from "../planner-masks/PlannerMask";
import { ProposalOptionsOverlayV2 } from "../proposal-options-overlay-v2/ProposalOptionsOverlay";
import { ProposalOptionsOverlayToggleV2 } from "../proposal-options-overlay-v2/ProposalOptionsOverlayToggle";
import { useProposalOptionsOverlayToggleV2 } from "../proposal-options-overlay-v2/useProposalOptionsOverlayToggle";
import { ProposalOptionsOverlay } from "../proposal-options-overlay/ProposalOptionsOverlay";
import { ProposalOptionsOverlayToggle } from "../proposal-options-overlay/ProposalOptionsOverlayToggle";
import { useProposalOptionsOverlayToggle } from "../proposal-options-overlay/useProposalOptionsOverlayToggle";
import {
  ProposalSearchRangeOverlay,
  ProposalSearchRangeUnderlay,
} from "../search-range/ProposalSearchRangeLayers";
import { TimeSuggestion } from "../time-suggestion";
import { getDateTimeFromPosition } from "../utils/getDateTimeFromPosition";
import { CalendarHorizontalGuideLines } from "./CalendarHorizontalGuideLines";
import { CalendarScrollSync } from "./CalendarScrollSync";
import { ProposalTimeOverlay } from "./ProposalTimeOverlay";
import { dateToDayOfWeek } from "./utils/dateToDayOfWeek";
import { getDefaultEventName } from "./utils/defaultEventName";
import { getCardsFromCardsByDay } from "./utils/getCardsFromCardsByDay";
import { parseWorkingHourBounds } from "./utils/parseWorkingHourBounds";

type Next = (_: Record<string, string | number | boolean>) => Promise<unknown>;

const DEFAULT_PROPOSAL_DURATION = 30; // In minutes.
const DEFAULT_CLICK_DRAG_PROPOSAL_DURATION = 15; // In minutes.

export const CalendarWeek = () => {
  const {
    calendarIds,
    allDayEvents: allDayEventsNullable,
    workingLocationsByDay: workingLocationsByDayNullable,
    conflictClusters,
    primaryCalendarId,
    multiCalendarIds,
    popoverClosing,
    setIsDraggingEvent,
    popoverOpen,
    scrollContainerRef,
  } = usePlannerContext();
  const { topCollaborators, customCollaborators } = useCollaborators();
  const track = useTracking();
  const { preferredConferencingType } = useUserConfrencingTypes();
  const [clickToCreateEnabled] = useFeatureFlag("ClickToCreate");
  const { initOrRescheduleProposal, updateTime, updateProposalMeta } = useUpdateCurrentProposal();
  const { currentProposal } = useCurrentProposal();
  const { onDemandDefrag } = useDefragProposal();
  const { isDragging: eventIsDragging, data: eventDragData } = useContext(CalendarWeekDNDContext);
  const { containsActiveProposal: chatContainsActiveProposal } = useAIMessageContext();
  const canModifyDraggingEvent = eventDragData?.current?.canModify;

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [activeConflict, setActiveConflict] = useState<ConflictClusterWithConversationId | null>(
    null,
  );
  const { userProfile } = useUserProfile();

  const calendarWeekRef = useRef<HTMLDivElement>(null);

  const calendarState = useReadCalendar();
  const activeEvent = useReadActiveEvent();
  const updateActiveEvent = useUpdateActiveEvent();
  const updateActiveEventDiff = useUpdateActiveEventDiff();

  const [datesToRender, setDatesToRender] = useState<string[]>(calendarState.visibleDates);
  const zone = getRenderTimeZone();
  const dateTimesToRender = React.useMemo(
    () => datesToRender.map((d) => DateTime.fromISO(d, { zone })),
    [datesToRender, zone],
  );

  const { intervalsByDay: availablities } = useAvailablities({
    dateTimes: dateTimesToRender,
    minCalendarCount: 2,
  });
  const calendarDensity = useReadLocalStorage<CalendarDensity>("calendarDensity");

  const { calendarWeekWidthInPx, dayWidthInPx, numOfDaysShown, calHeight } = useCalendarDimensions({
    numColumns: dateTimesToRender.length,
    ref: calendarWeekRef,
    calendarDensity,
  });

  const sidebarContainsActiveEvent = activeEvent && activeEvent.type !== EventType.Proposal;
  const isViewingProposalOrEvent =
    sidebarContainsActiveEvent ||
    chatContainsActiveProposal ||
    popoverClosing ||
    onDemandDefrag.visible;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // We only want to act if the user clicks on the calendar itself, not on any of the children.
    if (event.target !== event.currentTarget) return;

    const clickedTime = getDateTimeFromPosition({
      yPosition: event.clientY,
      xPosition: event.clientX,
      calendarContainerRef: calendarWeekRef,
      dateTimesToRender: dateTimesToRender,
    });
    createProposal(clickedTime);
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    // We only want to act if the user clicks on the calendar itself, not on any of the children.
    if (event.target !== event.currentTarget) return;

    const clickedTime = getDateTimeFromPosition({
      yPosition: event.clientY,
      xPosition: event.clientX,
      calendarContainerRef: calendarWeekRef,
      dateTimesToRender: dateTimesToRender,
    });
    initializeClickDragToCreate(clickedTime);
  };

  const handleClickProposalOptionsOverlay = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target !== event.currentTarget) return;

    const clickedTime = getDateTimeFromPosition({
      yPosition: event.clientY,
      xPosition: event.clientX,
      calendarContainerRef: calendarWeekRef,
      dateTimesToRender: dateTimesToRender,
    });
    updateProposalTime(clickedTime);
  };

  const handleClickCalendarConflicts = (event: React.MouseEvent<HTMLDivElement>, id: string) => {
    if (!conflictClusters?.length) return;
    setAnchorEl(event.currentTarget);
    const conflictCluster = conflictClusters.find((c) => c.id === id);
    setActiveConflict(conflictCluster || null);
  };

  const handleCloseCalendarConflicts = () => {
    setAnchorEl(null);
    setActiveConflict(null);
  };

  // The reason why we need `visibleDateTimes` is because without it, the <PlannerHead /> component
  // and <AllDayEvents /> component will flicker after changing the date range to previous or next week.
  // This is because the `calendarState.visibleDates` are updating only after an animation is completed.
  // Thus we need to calculate the date times to render immediately to avoid flickering.
  const visibleDateTimes = calendarState.visibleDates.map((d) => DateTime.fromISO(d, { zone }));

  // DevNote: we may look to lift this logic up to provider in future as this data format may no longer be needed
  const allDayEventCards = getCardsFromCardsByDay(allDayEventsNullable, visibleDateTimes);

  const workingLocationCards = getCardsFromCardsByDay(
    workingLocationsByDayNullable,
    visibleDateTimes,
  );

  const highlightedSlotOnPlanner = useSelector(
    (state: IReduxState) => state.plannerHighlights.slot,
  );
  const slotMeetingTitle = useSelector(
    (state: IReduxState) => state.plannerHighlights.meetingTitle,
  );

  const { isOverlayVisible: proposalOptionsOverlayVisible } = useProposalOptionsOverlayToggle();
  const {
    isToggleOverlayVisible: proposalOptionsOverlayVisibleV2,
    isSearchRangeOverlayVisible,
  } = useProposalOptionsOverlayToggleV2();

  const additionalChanges = highlightedSlotOnPlanner?.additionalChanges || [];

  const highlightStart = highlightedSlotOnPlanner
    ? DateTime.fromISO(highlightedSlotOnPlanner.timeRange.startTime.dateOrDateTime)
    : undefined;
  const highlightEnd = highlightedSlotOnPlanner
    ? DateTime.fromISO(highlightedSlotOnPlanner.timeRange.endTime.dateOrDateTime)
    : undefined;
  const [animateCalStyles, animateCal] = useSpring(() => ({}));
  const calendarContainerRef = useRef<HTMLDivElement | null>(null);

  const {
    scrollTopOfScrollContainerBeforeDragging,
    debouncedSetScrollTopOfScrollContainerBeforeDragging,
  } = useScrollTopOfScrollContainerBeforeDragging({
    scrollContainerRef: scrollContainerRef || null,
  });

  const animateCalendar = useCallback(
    (goForward: boolean, goToNewDate: () => void) => {
      const distance = 16, // React spring seems to do best with an even number here.
        duration = 135;
      void animateCal.start(() => ({
        to: async (next: Next) => {
          await next({ translateX: -distance * (goForward ? 1 : -1), opacity: 0.25 });
          await next({
            translateX: distance * 1.8 * (goForward ? 1 : -1),
            opacity: 0,
            immediate: true,
          });
          await next({ immediate: true, opacity: 0.5 });
          goToNewDate();
          await next({ translateX: 0, opacity: 1 });
        },
        config: { duration },
      }));
    },
    [animateCal],
  );

  useEffect(() => {
    // if transition is day <-> week, update without animation
    if (datesToRender.length !== calendarState.visibleDates.length) {
      setDatesToRender(calendarState.visibleDates);
      return;
    }

    // if the render dates are the same, don't animate
    if (isEqual(datesToRender, calendarState.visibleDates)) {
      return;
    }

    // determine if we should animate forward or backward
    const currentFirstRenderDateTime = dateTimesToRender[0];
    const newFirstRenderDateTime = DateTime.fromISO(calendarState.visibleDates[0]);
    const stepForward = newFirstRenderDateTime > currentFirstRenderDateTime;

    // animate the calendar, and update local state
    animateCalendar(stepForward, () => {
      setDatesToRender(calendarState.visibleDates);
    });
  }, [animateCalendar, calendarState.visibleDates, dateTimesToRender, datesToRender]);

  const handleSelectEvent = useCallback(
    (calendarId: string, externalEventId: string, type: EventType) => {
      updateActiveEventDiff(null);
      updateActiveEvent({ calendarId, externalEventId, type });
    },
    [updateActiveEvent, updateActiveEventDiff],
  );

  const getPxPer15MinuteBlock = () => {
    const height = calendarWeekRef.current?.offsetHeight ?? 0;
    /**
     * We want to break the whole day into 15 minute blocks, so that we can calculate which block the user clicked on.
     * The way I'm approaching this is by dividing the height of the calendar by the number of 15 minute blocks in a
     * day, because we show the full 24 hours. There are 96 15 minute blocks in a day (24 hours * 4 blocks per hour).
     */
    const pxPerBlock = height / 96; // 15 minute blocks

    return pxPerBlock;
  };

  const attendees = useMemo(() => {
    return compact([primaryCalendarId, ...(multiCalendarIds ?? [])]);
  }, [primaryCalendarId, multiCalendarIds]);

  const defaultTitle = useMemo(() => {
    return getDefaultEventName({
      attendees,
      collaborators: [...topCollaborators, ...customCollaborators],
      myProfile: userProfile,
    });
  }, [attendees, topCollaborators, customCollaborators, userProfile]);

  const defaultConferencingType = useMemo(() => {
    return attendees.length > 1 ? preferredConferencingType : null;
  }, [preferredConferencingType, attendees]);

  const createProposal = (clickedTime: DateTime) => {
    if (!clickToCreateEnabled) return;

    // We don't want to create a new event if:
    // - there is an active event, e.g. the sidebar is open with an existing event
    // - there is an active proposal in the chat
    // - there is an event popover open
    if (isViewingProposalOrEvent) {
      return;
    }

    // We don't want to create a new event on click
    // if we are click dragging to create
    if (hasInitializedClickDragToCreateToReducer.current) {
      return;
    }

    const chosenTimeRange = Interval.fromDateTimes(
      clickedTime,
      clickedTime.plus({ minutes: DEFAULT_PROPOSAL_DURATION }),
    );

    handleSelectEvent("", "", EventType.Proposal);
    track(TrackingEvents.DIRECT_MANIPULATION.CALENDAR.CLICK_TO_CREATE);

    initOrRescheduleProposal({
      proposalId: null,
      title: defaultTitle,
      eventCalendarId: primaryCalendarId,
      startTime: chosenTimeRange.start,
      endTime: chosenTimeRange.end,
      timeZone: getRenderTimeZone(),
      initialAttendeeIds: attendees,
      attendees: [],
      allDay: false,
      description: "",
      recurrenceRule: null,
      location: null,
      conferenceType: defaultConferencingType,
      flexDetails: {
        isFlexible: attendees.length > 1,
        flexRange: FlexRange.Day,
      },
      meta: {
        isDragging: false,
        isFindATime: false,
      },
      transparency: "opaque",
      visibility: "default",
    });
  };

  const updateProposalTime = (startTime: DateTime) => {
    const existingDurationMinutes = currentProposal.endTime
      .diff(currentProposal.startTime)
      .as("minutes");
    const endTime = startTime.plus({ minutes: existingDurationMinutes });

    updateTime(startTime, endTime);
  };

  const chosenTimeRef = useRef<DateTime>();
  // UX Note: we need to this `hasInitializedClickDragToCreateToReducer` flag
  // in order to differential between a `clickDragToCreate` mouseDown event
  // vs a and `clickToCreate` mouseDown, which at the beginning there's no way to tell until
  // user continues with their mouse action (either dragging or mousing up)
  const hasInitializedClickDragToCreateToReducer = useRef<boolean>(false);

  const initializeClickDragToCreate = (clickedTime: DateTime) => {
    if (!clickToCreateEnabled) return;

    chosenTimeRef.current = clickedTime;

    // Reset the flag so we can run the detection logic on initialization
    hasInitializedClickDragToCreateToReducer.current = false;
  };

  const {
    listeners: listenersResize,
    setNodeRef: setNodeRefResize,
    transform: transformResize,
    isDragging: isClickDraggingToCreate,
  } = useDraggable({
    id: `click-drag-to-create`,
    disabled: !clickToCreateEnabled,
    data: {
      isClickDragToCreate: true,
    },
  });

  useEffect(() => {
    // This useEffect has two responsibilities:
    // 1. Initializing the proposal
    // 2. updateing the proposal time during drag changes
    const initialClickTime = chosenTimeRef.current;

    if (!isClickDraggingToCreate || !initialClickTime) return;
    // We don't want to create a new event if:
    // - there is an active event, e.g. the sidebar is open with an existing event
    // - there is an active proposal in the chat
    // - there is an event popover open
    if (isViewingProposalOrEvent) {
      return;
    }

    const pxPerBlock = getPxPer15MinuteBlock();

    // UX Note: use Math.ceil to give a quick responsiveness feeling for user
    // And always make it non-zero
    const num15minBlocks = (transformResize?.y || 0) / pxPerBlock;

    // If the user is clickdragging up, we default the delta minutes to a negative number
    // to set the start time to be where the user drags to
    const defaultNumOfBlocks = Math.sign(num15minBlocks) === -1 ? -1 : 1;
    const nonZeroNumOfBlocks = Math.ceil(num15minBlocks) || defaultNumOfBlocks;
    const deltaMinutes = nonZeroNumOfBlocks * 15;

    const otherTimeBoundary = initialClickTime.plus({
      minutes: deltaMinutes,
    });
    // UX Note: this math is for support dragging up and dragging down
    // in the case of dragging up, the `initialClickTime` is the `endTime`
    const startTime = deltaMinutes < 0 ? otherTimeBoundary : initialClickTime;
    const endTime = deltaMinutes < 0 ? initialClickTime : otherTimeBoundary;

    // If the proposal has already been initialized, for eg by clicking to create
    // we should just update the time
    if (!hasInitializedClickDragToCreateToReducer.current && !currentProposal.initialized) {
      const chosenTimeRange = Interval.fromDateTimes(
        startTime,
        startTime.plus({ minutes: DEFAULT_CLICK_DRAG_PROPOSAL_DURATION }),
      );
      setIsDraggingEvent?.(true);

      initOrRescheduleProposal({
        proposalId: null,
        title: defaultTitle,
        eventCalendarId: primaryCalendarId,
        startTime: chosenTimeRange.start,
        endTime: chosenTimeRange.end,
        timeZone: getRenderTimeZone(),
        initialAttendeeIds: attendees,
        attendees: [],
        allDay: false,
        description: "",
        recurrenceRule: null,
        location: null,
        conferenceType: defaultConferencingType,
        flexDetails: {
          isFlexible: attendees.length > 1,
          flexRange: FlexRange.Day,
        },
        meta: {
          isDragging: true,
          isFindATime: false,
        },
        transparency: "opaque",
        visibility: "default",
      });

      hasInitializedClickDragToCreateToReducer.current = true;
    } else {
      updateTime(startTime, endTime);
      updateProposalMeta({ isDragging: true });
    }
  }, [
    transformResize?.y,
    isClickDraggingToCreate,
    isViewingProposalOrEvent,
    currentProposal.initialized,
    setIsDraggingEvent,
    initOrRescheduleProposal,
    defaultTitle,
    primaryCalendarId,
    attendees,
    defaultConferencingType,
    updateTime,
    updateProposalMeta,
  ]);

  const isClickDraggingToCreatePrevRef = useRef(isClickDraggingToCreate);
  useEffect(() => {
    // This useEffect handles when an `onDragEnd` event happens

    // We can detect an `onDragEnd` with `isClickDraggingToCreate` and it's previous value
    const isOnDragEnd = isClickDraggingToCreatePrevRef.current && !isClickDraggingToCreate;

    // Set the new previous value no matter what
    isClickDraggingToCreatePrevRef.current = isClickDraggingToCreate;
    if (!isOnDragEnd) return;

    // We don't want to create a new event if:
    // - there is an active event, e.g. the sidebar is open with an existing event
    // - there is an active proposal in the chat
    // - there is an event popover open
    // - a proposal hasn't been initialized. Without this, click-drag events on the time rail will trigger downstream logic and bugs
    if (isViewingProposalOrEvent || currentProposal.initialized === false) {
      return;
    }

    handleSelectEvent("", "", EventType.Proposal);
    track(TrackingEvents.DIRECT_MANIPULATION.CALENDAR.CLICK_DRAG_TO_CREATE);
    updateProposalMeta({ isDragging: false });
    setIsDraggingEvent?.(false);
  }, [
    currentProposal.initialized,
    handleSelectEvent,
    isClickDraggingToCreate,
    isViewingProposalOrEvent,
    setIsDraggingEvent,
    track,
    updateProposalMeta,
  ]);

  if (!calendarIds || !scrollContainerRef) {
    return null;
  }

  const hideScrollBar = popoverOpen || (eventIsDragging && !canModifyDraggingEvent);

  return (
    <animated.div
      style={animateCalStyles}
      className="cw-flex cw-flex-1 cw-flex-col cw-mt-6 cw-w-[calc(100%-12px)] cw-h-full"
    >
      <div className="cw-relative cw-flex-1 cw-w-full cw-h-full cw-flex cw-flex-col cw-my-0 cw-mx-3 cw-pb-3">
        <div className="cw-border-b cw-border-subtle cw-border-solid">
          <div className="cw-w-[40px]"></div>
          <PlannerHead
            dateTimes={visibleDateTimes}
            events={allDayEventCards}
            workingLocations={[...workingLocationCards]}
            onEventClick={handleSelectEvent}
          />
        </div>
        <ProposalOptionsOverlayToggle overlayVisible={proposalOptionsOverlayVisible} />
        <ProposalOptionsOverlayToggleV2 overlayVisible={proposalOptionsOverlayVisibleV2} />
        <div
          className={classNames(
            "cw-mb-[66px] cw-flex-grow cw-scrollbar-gutter-stable cw-scrollbar-width-thin",
            {
              "cw-overflow-auto": !hideScrollBar,
              // `cw-overflow-hidden` prevents vertical scrolling while
              // dragging the cursor to the end of screen
              // when user cannot modify the dragged event
              "cw-overflow-hidden": hideScrollBar,
            },
          )}
          ref={scrollContainerRef}
          onWheel={(e) => {
            // Dev note: this handles a DnDKit UX bug
            // where the mouse scroll wheel doesn't work during a drag (before a drop)
            // We will manually scroll (whenever the mouse whell scrolls)

            if (!eventIsDragging || !scrollContainerRef?.current) return;

            scrollContainerRef.current.scrollTo({
              top: scrollContainerRef.current.scrollTop - e.deltaY,
              behavior: "smooth",
            });
          }}
          onScroll={() => {
            if (eventIsDragging) return;

            // UX Note: if this isn't debounced it'll slow down scrolling!
            // And make the calendar scroll feel heavy!
            debouncedSetScrollTopOfScrollContainerBeforeDragging(
              scrollContainerRef?.current?.scrollTop || 0,
            );
          }}
        >
          <div
            style={{ minHeight: calHeight }}
            className="cw-relative cw-flex cw-flex-row cw-flex-1 cw-h-full"
            ref={(el) => {
              // This `el` has to be used for both refs
              calendarContainerRef.current = el;
              setNodeRefResize(el);
            }}
            {...listenersResize}
          >
            {/* Non-rendering component that adjusts the scrollTop based on:
                1. On first mount, any previous scroll events initiated by the user
                2. The current time on routing to /app/ai-scheduler (via extension)
                3. The earliest start time in a conversation (proposal or viewEvents)
             */}
            <CalendarScrollSync
              scrollContainerRef={scrollContainerRef}
              calendarContainerRef={calendarContainerRef}
              scrollBehavior="instant"
            />
            <CalendarTimeDial />
            <CalendarDimensionsContext.Provider
              value={{
                calendarWeekWidthInPx,
                numOfDaysShown,
                calendarWeekHeightInPx: calHeight + 1, // This is based `cw-h-[calc(100%+1px)]` on `calendarWeekRef`
                dayWidthInPx,
                scrollContainerRef,
                scrollTopOfScrollContainerBeforeDragging,
              }}
            >
              <div
                ref={calendarWeekRef}
                id="calendar-week-container"
                className="cw-flex cw-flex-row cw-flex-1 cw-ml-5 cw-relative cw-h-[calc(100%+1px)] cw-select-none"
                onClick={handleClick}
                onMouseDown={handleMouseDown}
              >
                <CalendarAvailability dateTimes={dateTimesToRender} availablities={availablities} />
                {isSearchRangeOverlayVisible && (
                  <ProposalSearchRangeUnderlay dateTimes={dateTimesToRender} />
                )}
                <OOOEventsUnderlay dateTimes={dateTimesToRender} />
                <CalendarHorizontalGuideLines />
                <CalendarWorkHourBounds />
                <CalendarVerticalGuideLines dateTimes={dateTimesToRender} />
                {isSearchRangeOverlayVisible && (
                  <ProposalSearchRangeOverlay dateTimes={dateTimesToRender} />
                )}
                <DateTimeMarker daysRendered={dateTimesToRender} />
                <PlannerEvents dateTimes={dateTimesToRender} />
                <ProposalTimeOverlay
                  dateTimes={dateTimesToRender}
                  reserveRail={proposalOptionsOverlayVisible || proposalOptionsOverlayVisibleV2}
                />
                {highlightStart && highlightEnd && (
                  <PlannerMask
                    key="selected-slot"
                    type="selected-slot"
                    start={highlightStart}
                    end={highlightEnd}
                    text={
                      slotMeetingTitle ||
                      `${highlightStart.toFormat("h:mm")} - ${highlightEnd.toFormat("h:mm")}`
                    }
                  />
                )}
                <CalendarConflicts
                  dateTimes={dateTimesToRender}
                  activeConflictId={activeConflict?.id}
                  conflictClusters={conflictClusters || []}
                  onNodeClick={handleClickCalendarConflicts}
                />
                {additionalChanges.map(({ event, to }) => {
                  const { events } = event;
                  const oneOfTheEvents = events[0];
                  const { startTime, endTime } = oneOfTheEvents;
                  const duration = DateTime.fromISO(endTime.dateOrDateTime)
                    .diff(DateTime.fromISO(startTime.dateOrDateTime))
                    .as("milliseconds");

                  return (
                    <PlannerMask
                      key={`suggested-move-${event.id}`}
                      type="suggested-move"
                      start={DateTime.fromISO(to)}
                      end={DateTime.fromISO(to).plus({ milliseconds: duration })}
                      text={events[0].title}
                    />
                  );
                })}
                <ConflictPopover
                  activeConflict={activeConflict}
                  anchorEl={anchorEl}
                  onClose={handleCloseCalendarConflicts}
                  calendarId={userProfile?.primaryEmail || ""}
                />

                {!proposalOptionsOverlayVisible && <TimeSuggestion dateTimes={dateTimesToRender} />}
                <RescheduleConfirmationWrapper />
                {proposalOptionsOverlayVisible && (
                  <ProposalOptionsOverlay dateTimes={dateTimesToRender} includeRail />
                )}
                {proposalOptionsOverlayVisibleV2 && (
                  <ProposalOptionsOverlayV2
                    dateTimes={dateTimesToRender}
                    onClick={handleClickProposalOptionsOverlay}
                  />
                )}
              </div>
            </CalendarDimensionsContext.Provider>
          </div>
        </div>
      </div>
    </animated.div>
  );
};

const CalendarWorkHourBounds = () => {
  const { workingHours } = usePlannerContext();

  const { visibleDates } = useReadCalendar();
  const visibleDays = visibleDates.map((date) => DateTime.fromFormat(date, "yyyy-MM-dd"));
  const visibleDaysOfWeek = visibleDays.map(dateToDayOfWeek);

  // This is an internal feature only used for testing calendar camera position.
  // It makes it easier to see the boundaries of the work hours.
  // If you want to turn it on, go to local storage and set the key "ShowWeeksWorkHourBoundaries" to `true` manually.
  const shouldShowWeeksWorkHourBoundaries = showWeeksWorkHourBoundaries.get();

  if (!shouldShowWeeksWorkHourBoundaries) {
    return null;
  }

  const daySettingsForVisibleDaysOfWeek = (workingHours?.daySettings || []).filter((daySetting) => {
    if (!daySetting || !daySetting.day) {
      return false;
    }

    return visibleDaysOfWeek.includes(daySetting.day);
  });

  const workingHourBoundsForVisibleDays = parseWorkingHourBounds(daySettingsForVisibleDaysOfWeek);
  const workingHourBounds = workingHourBoundsForVisibleDays;
  const [startWorkingHours, endWorkingHours] = workingHourBounds || [0, 24];

  return (
    <div className="cw-absolute cw-h-full cw-w-full cw-pointer-events-none cw-z-50">
      {[startWorkingHours, endWorkingHours].map((i) => {
        const top = i === 0 ? -1 : `${(100 * i) / 24}%`;
        return (
          <div
            key={`work-hour-bound-for-${visibleDays.join("-")}-${i}`}
            className="cw-absolute cw-border-b-2 cw-border-solid cw-border-b-[pink] cw-right-0 cw-left-0"
            style={{ top }}
          />
        );
      })}
    </div>
  );
};

const CalendarVerticalGuideLine = ({ dateTime, index }: { dateTime: DateTime; index: number }) => {
  const { setNodeRef } = useDroppable({
    id: index,
  });

  return (
    <section
      ref={setNodeRef}
      id="calendar-vertical-guide-lines"
      aria-label={`Events on ${dateTime.toLocaleString(DateTime.DATETIME_FULL)}`}
      className="cw-h-full cw-w-full cw-border-r cw-border-solid cw-border-r-subtle"
    ></section>
  );
};
const CalendarVerticalGuideLines = ({ dateTimes }: { dateTimes: DateTime[] }) => {
  const positionables = useMemo(
    () =>
      dateTimes.map((dateTime, index) => ({
        key: dateTime.toISO(),
        interval: Interval.fromDateTimes(dateTime.startOf("day"), dateTime.endOf("day")),
        render: () => <CalendarVerticalGuideLine dateTime={dateTime} index={index} />,
      })),
    [dateTimes],
  );

  if (dateTimes.length <= 1) {
    return null;
  }

  return (
    <div className="cw-pointer-events-none">
      <CalendarPositioner dateTimes={dateTimes} positionables={positionables} />
    </div>
  );
};
