import { Tab as HeadLessTab } from "@headlessui/react";
import classNames from "classnames";
import React from "react";

interface TabsProps {
  tabs: {
    title: React.ReactNode;
    content: React.ReactNode;
    key: string;
  }[];
  onChange?: (index: number) => void;
  defaultTabIndex?: number;
}

export const Tabs = ({ tabs, defaultTabIndex = 0, onChange }: TabsProps) => {
  return (
    <HeadLessTab.Group onChange={onChange} defaultIndex={defaultTabIndex}>
      <HeadLessTab.List className="cw-flex">
        {tabs.map((tab) => (
          <HeadLessTab
            key={tab.key}
            className={({ selected }) =>
              classNames(
                "cw-w-full cw-font-body cw-font-bold cw-bg-none cw-bg-default cw-px-4 cw-py-2 cw-outline-none cw-cursor-pointer",
                "cw-border-t-0 cw-border-l-0 cw-border-r-0 cw-border-b-2 cw-border-solid",
                {
                  "cw-text-positive cw-border-brand-pressed": selected,
                },
                {
                  "cw-border-muted hover:cw-border-muted-hover cw-text-muted hover:cw-text-neutral cw-border-b cw-pb-[9px]": !selected,
                },
              )
            }
          >
            {tab.title}
          </HeadLessTab>
        ))}
      </HeadLessTab.List>
      <HeadLessTab.Panels>
        {tabs.map((tab) => (
          <HeadLessTab.Panel key={tab.key} className="cw-my-5">
            {tab.content}
          </HeadLessTab.Panel>
        ))}
      </HeadLessTab.Panels>
    </HeadLessTab.Group>
  );
};
