import { ApolloError } from "@apollo/client";
import { EditEventAppliesTo, ProposalAppliesTo, ProposalState } from "@clockwise/schema/v2";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import {
  EventType,
  useUpdateActiveEvent,
} from "@clockwise/web-commons/src/util/ActiveEventContext";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { noop } from "lodash";
import { DateTime, Interval } from "luxon";
import { Maybe } from "purify-ts";
import React, { useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useFeatureFlag } from "../../launch-darkly";
import {
  clearSpecificSelectedRescheduleOption,
  SelectedRescheduleOptionEntrypoint,
} from "../../state/actions/reschedule-event-modal.actions";
import { IReduxState } from "../../state/reducers/root.reducer";
import { logger } from "../../util/logger.util";
import { useUpdateCurrentProposal } from "../chat-plus-calendar/CurrentProposalContext";
import { useUpdateActiveEventDiff } from "../chat-plus-calendar/util/ActiveDiffContext";
import { useFocusChatTextInput } from "../chat/ai-chat/hooks/useFocusChatTextInput";
import { useProcessMessage } from "../chat/ai-chat/hooks/useProcessMessage";
import { useScrollChatToBottom } from "../chat/ai-chat/hooks/useScrollChatToBottom";
import { useMoveEventTime } from "../event-card/hooks/useMoveEventTime";
import {
  generateRescheduleCQL,
  generateRescheduleDurationAndFindMoreTimesCQL,
} from "../event-card/utils/generateCQL";
import { RescheduleEventGatewayMutation } from "../hooks/__generated__/RescheduleEventGateway.v2.generated";
import { useRescheduleEvent } from "../hooks/useRescheduleEvent";
import { usePlannerContext } from "../web-app-calendar/Context";
import { MoveEventTimeMutation } from "../web-app-calendar/apollo/__generated__/MoveEventTime.v2.generated";
import { RescheduleConfirmationModal } from "./RescheduleConfirmationModal";
import { CancelRescheduleProposalDocument } from "./__generated__/CancelRescheduleProposal.v2.generated";
import { ConfirmRescheduleProposalDocument } from "./__generated__/ConfirmRescheduleProposal.v2.generated";
import { CreateProposalFromEventDocument } from "./__generated__/CreateProposalFromEvent.v2.generated";
import { useProposalTradeoffBlocks } from "./useProposalTradeoffBlocks";

export const RescheduleConfirmationWrapper = () => {
  const track = useTracking();
  const { refetchEvents } = usePlannerContext();
  const [isOnDNDProposal] = useFeatureFlag("DNDProposal");
  const [timeSuggestionsTabEnabled] = useFeatureFlag("TimeSuggestionsTab");
  const updateActiveEvent = useUpdateActiveEvent();
  const { selectExistingEvent } = useUpdateCurrentProposal();
  const updateActiveEventDiff = useUpdateActiveEventDiff();

  const selectedSchedulingOption = useSelector(
    (state: IReduxState) => state.rescheduleEventModal.selectedRescheduleOption,
  );
  const newDuration = selectedSchedulingOption?.newDuration;
  const isHold = selectedSchedulingOption?.isHold;

  const timeZone = getRenderTimeZone();
  const {
    proposedStartTimeISO,
    duration,
    externalEventId,
    calendarId,
    showFindMoreTimes,
    isRecurring: selectedSchedulingOptionIsRecurring,
  } = selectedSchedulingOption || {};

  const [createProposal, { data: proposalData }] = useGatewayMutation(
    CreateProposalFromEventDocument,
    {
      errorPolicy: "all",
    },
  );
  const [confirmProposal] = useGatewayMutation(ConfirmRescheduleProposalDocument);
  const [cancelProposal] = useGatewayMutation(CancelRescheduleProposalDocument);

  useEffect(() => {
    if (!isOnDNDProposal) return;

    if (proposedStartTimeISO && externalEventId) {
      const startTime = DateTime.fromISO(proposedStartTimeISO);
      const endTime = startTime.plus({ minutes: newDuration || duration });
      const timeRange = Interval.fromDateTimes(startTime, endTime);
      void createProposal({
        variables: {
          eventId: externalEventId,
          timeRange: timeRange.toISO(),
          timeZone: timeZone,
        },
      });
    }
  }, [createProposal, duration, externalEventId, isOnDNDProposal, proposedStartTimeISO, timeZone]);

  const proposalId = proposalData?.createProposalFromEvent?.id;
  const initialTradeoffBlocks = Maybe.fromNullable(proposalData?.createProposalFromEvent)
    .map((proposal) => proposal.diffBlocks)
    .map((diffBlocks) => diffBlocks[0].diffs[0])
    .map((diff) => (diff.__typename === "ModifyDiff" ? diff.tradeoffBlocks : []))
    .orDefault([]);
  const initialProposalState = Maybe.fromNullable(
    proposalData?.createProposalFromEvent?.state,
  ).orDefault(ProposalState.LoadingSuggestions);

  const {
    tradeoffBlocks: polledTradeoffBlocks,
    proposalState: polledProposalState,
    recurrence: polledRecurrence,
  } = useProposalTradeoffBlocks(proposalData?.createProposalFromEvent?.id);
  const tradeoffBlocks = polledTradeoffBlocks ?? initialTradeoffBlocks;
  const proposalState = polledProposalState ?? initialProposalState;
  const isRecurring = selectedSchedulingOptionIsRecurring || !!polledRecurrence;
  const disableSave = isOnDNDProposal && proposalState === ProposalState.LoadingSuggestions;
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    // UX Note: In the case of recurrence events, we close the modal before the refetchEvents come back
    // which allows the user to open the modal again with a different event selected
    // This will cause race conditions and UX issues
    // We avoid that by only clearing the correct specific event
    dispatch(clearSpecificSelectedRescheduleOption(externalEventId));
    // More Important Dev Notes:
    // If `clearSpecificSelectedRescheduleOption` successfully clears the selected event, we can close the modal
    // via a useEffect that listens to the `selectedSchedulingOption` change
    // This will also prevent race conditions for proposal confirmation then opening the modal again
  }, [externalEventId, dispatch]);

  const rescheduleCallback = () => {
    toast.success("Event rescheduled");
    handleClose();
  };

  const onError = (error: ApolloError) => {
    toast.error("Failed to reschedule event");
    logger.error("Failed to reschedule event in RescheduleConfirmationModal", error);
  };

  const { rescheduleEvent } = useRescheduleEvent(rescheduleCallback, onError);
  const { moveEventTime } = useMoveEventTime(rescheduleCallback, onError);
  const { moveEventTime: moveEventTimeNoCallback } = useMoveEventTime(noop, onError);
  const [isModalOpen, setIsModalOpen] = React.useState(true);

  const handleCloseAndCancelProposal = () => {
    if (isOnDNDProposal) {
      onCancelProposal();
    }
    handleClose();
  };

  const makeMoveEventTimeCallback = useCallback(
    (
      moveEventTimeFunction: typeof moveEventTime,
      externalEventId: string,
      start: string,
      appliesTo?: EditEventAppliesTo,
    ) => {
      if (!selectedSchedulingOption) return;

      const timeRange = Interval.fromDateTimes(
        DateTime.fromISO(start),
        DateTime.fromISO(start).plus({
          minutes: selectedSchedulingOption.newDuration || selectedSchedulingOption.duration,
        }),
      ).toISO();

      const optimisticResponse: MoveEventTimeMutation = {
        __typename: "Mutation",
        moveEventTime: {
          __typename: "Event",
          id: selectedSchedulingOption.id ?? "",
          externalEventId,
          title: selectedSchedulingOption.title,
          smartHold: null,
          dateOrTimeRange: {
            __typename: "DateTimeRange",
            timeRange,
          },
        },
      };
      return moveEventTimeFunction({ externalEventId, timeRange, optimisticResponse, appliesTo });
    },
    [selectedSchedulingOption],
  );

  const onMoveEventTime = useCallback(
    (externalEventId: string, start: string, appliesTo?: EditEventAppliesTo) =>
      makeMoveEventTimeCallback(moveEventTime, externalEventId, start, appliesTo),
    [moveEventTime, makeMoveEventTimeCallback],
  );

  const onMoveEventTimeNoCallback = useCallback(
    (externalEventId: string, start: string, appliesTo?: EditEventAppliesTo) =>
      makeMoveEventTimeCallback(moveEventTimeNoCallback, externalEventId, start, appliesTo),
    [moveEventTimeNoCallback, makeMoveEventTimeCallback],
  );

  const onRescheduleEvent = (externalEventId: string, start: string) => {
    if (!selectedSchedulingOption) return;

    const timeRange = Interval.fromDateTimes(
      DateTime.fromISO(start),
      DateTime.fromISO(start).plus({
        minutes: selectedSchedulingOption.newDuration || selectedSchedulingOption.duration,
      }),
    ).toISO();

    const optimisticResponse: RescheduleEventGatewayMutation = {
      __typename: "Mutation",
      rescheduleEvent: {
        __typename: "Event",
        id: selectedSchedulingOption.id ?? "",
        externalEventId,
        title: selectedSchedulingOption.title,
        dateOrTimeRange: {
          __typename: "DateTimeRange",
          timeRange,
        },
      },
    };
    rescheduleEvent(externalEventId, start, timeZone, optimisticResponse);
  };

  const onConfirmProposal = () => {
    const appliesTo = ProposalAppliesTo.Instance;

    if (!proposalId) return;
    void confirmProposal({
      variables: { id: proposalId, appliesTo },
      onCompleted: () => {
        handleClose();
        void refetchEvents?.();
      },
      onError,
    });
    track(TrackingEvents.DIRECT_MANIPULATION.RESCHEDULE_MODAL.SAVE_AND_FIX_BUTTON.CLICKED, {
      proposalId,
    });
    setIsModalOpen(false);
  };

  const onCancelProposal = () => {
    const id = proposalData?.createProposalFromEvent?.id;
    if (!id) return;
    void cancelProposal({
      variables: { id },
    });
  };

  const onSaveNonRecurringEvent = (externalEventId: string, start: string) => {
    if (!selectedSchedulingOption) return;

    if (isOnDNDProposal || newDuration) {
      void onMoveEventTime(externalEventId, start);
      if (
        selectedSchedulingOption.entryPoint ===
        SelectedRescheduleOptionEntrypoint.DirectManipulation
      ) {
        track(TrackingEvents.DIRECT_MANIPULATION.RESCHEDULE_MODAL.SAVE_BUTTON.CLICKED, {
          recurrenceAppliesTo: EditEventAppliesTo.Instance,
          proposalState,
          proposalId,
        });
      }
    } else {
      onRescheduleEvent(externalEventId, start);
    }
    if (
      selectedSchedulingOption.entryPoint === SelectedRescheduleOptionEntrypoint.QuickReschedule
    ) {
      track(TrackingEvents.QUICK_RESCHEDULE_AI_SCHEDULER.CONFIRMED);
    }
    setIsModalOpen(false);
  };

  const onSaveRecurringEvent = (appliesTo: EditEventAppliesTo) => {
    if (!externalEventId) return;
    if (!proposedStartTimeISO) return;

    void onMoveEventTimeNoCallback(externalEventId, proposedStartTimeISO, appliesTo)?.then(() => {
      void refetchEvents?.();
      // UX: only after all the `refetchEvents` come back, then we'll toast the success message to the use
      toast.success("Event rescheduled");
      dispatch(clearSpecificSelectedRescheduleOption(externalEventId));
    });

    if (
      selectedSchedulingOption?.entryPoint === SelectedRescheduleOptionEntrypoint.DirectManipulation
    ) {
      track(TrackingEvents.DIRECT_MANIPULATION.RESCHEDULE_MODAL.SAVE_BUTTON.CLICKED, {
        recurrenceAppliesTo: appliesTo,
        proposalState,
        proposalId,
      });
    }

    setIsModalOpen(false);
  };

  const scrollChatToBottom = useScrollChatToBottom();
  const focusChatTextInput = useFocusChatTextInput();
  const { processMessage } = useProcessMessage(focusChatTextInput);

  const onClickFindMoreTimes = () => {
    if (timeSuggestionsTabEnabled && externalEventId && calendarId) {
      // Cleanup the confirmation modal
      setIsModalOpen(false);
      handleClose();

      // Create a Find more times proposal
      selectExistingEvent(externalEventId, calendarId, { isFindATime: true });
      updateActiveEventDiff(null);
      updateActiveEvent({ externalEventId: "", calendarId: "", type: EventType.Proposal });
    } else {
      submitRescheduleMessage();
    }
  };

  const submitRescheduleMessage = () => {
    if (!selectedSchedulingOption) {
      return;
    }

    const { proposedStartTimeISO, externalEventId, newDuration, title } = selectedSchedulingOption;

    const startTimeZoned = DateTime.fromISO(proposedStartTimeISO).setZone(getRenderTimeZone());

    const cql =
      newDuration && externalEventId
        ? generateRescheduleDurationAndFindMoreTimesCQL(externalEventId, newDuration)
        : externalEventId
        ? generateRescheduleCQL(externalEventId)
        : undefined;

    scrollChatToBottom();
    void processMessage(
      `Reschedule ${title}${newDuration ? ` to ${newDuration} minutes long` : ""}`,
      {
        eventMentions: [
          {
            externalEventId,
            startTime: startTimeZoned.toISO(),
            title,
          },
        ],
        personMentions: [],
      },
      cql,
    );

    track(TrackingEvents.DIRECT_MANIPULATION.RESCHEDULE_MODAL.FIND_MORE_TIMES_BUTTON.CLICKED, {
      proposalId,
    });
    handleCloseAndCancelProposal();
  };

  useEffect(() => {
    if (selectedSchedulingOption) {
      if (selectedSchedulingOption.isHold && !selectedSchedulingOption.isRecurring) {
        // Just perform the move without the modal.
        setIsModalOpen(false);
        onMoveEventTime(
          selectedSchedulingOption.externalEventId,
          selectedSchedulingOption.proposedStartTimeISO,
          EditEventAppliesTo.Instance,
        );
      } else {
        setIsModalOpen(true);
      }
    } else {
      // If the selectedSchedulingOption is cleared, close the modal
      // We're coupling `selectedSchedulingOption` with the modal open state
      // to avoid race conditions with proposal confirmation then opening the modal again
      setIsModalOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchedulingOption]);

  return (
    selectedSchedulingOption && (
      <RescheduleConfirmationModal
        isOpen={isModalOpen}
        title={selectedSchedulingOption.title}
        externalEventId={selectedSchedulingOption.externalEventId}
        onClose={handleCloseAndCancelProposal}
        proposedStart={selectedSchedulingOption.proposedStartTimeISO}
        duration={selectedSchedulingOption.duration}
        newDuration={selectedSchedulingOption.newDuration}
        existingStart={selectedSchedulingOption.currentStartTimeISO}
        onSaveNonRecurringEvent={onSaveNonRecurringEvent}
        onSaveRecurringEvent={onSaveRecurringEvent}
        onConfirmProposal={onConfirmProposal}
        submitRescheduleMessage={showFindMoreTimes ? onClickFindMoreTimes : undefined}
        tradeoffBlocks={tradeoffBlocks}
        disabled={disableSave}
        loading={disableSave}
        isRecurring={isRecurring}
        isHold={isHold}
        isOnDNDProposal={isOnDNDProposal}
      />
    )
  );
};
