import { IOrg } from "#webapp/src/__schema__";
import { getOrgOverview } from "#webapp/src/components/admin-panel/AdminPanel.util";
import { getCurrentEnvironment } from "#webapp/src/state/relay-environment";
import { handleReadyStateError } from "#webapp/src/util/relay.util";
import { DateTime } from "luxon";
import React from "react";
import { QueryRenderer, ReadyState } from "react-relay";
import { adminPanelOverviewQuery } from "./AdminPanelOverview.gql";
import { remapOrgOverviewLabels } from "./AdminPanelOverview.util";

import {
  getTimeframeDates,
  Timeframes,
  TimeframeSelect,
} from "@clockwise/web-commons/src/ui/admin-panel/overview/admin-select";
import { getTimeframeFromDates } from "@clockwise/web-commons/src/ui/admin-panel/overview/admin-select/timeframe";
import { ChartGrid } from "@clockwise/web-commons/src/ui/admin-panel/overview/chart-grid/chart-grid";
import { HeroStatGrid } from "@clockwise/web-commons/src/ui/admin-panel/overview/hero-stat-grid/hero-stat-grid";
import { Title } from "@clockwise/web-commons/src/ui/admin-panel/title";

import { logger } from "#webapp/src/util/logger.util";

import { Loader } from "@clockwise/design-system/src/components/Loader";
import { ExportableAnalyticsButton } from "./exportable-analytics";

export interface IAdminPanelOverviewContainerProps {
  orgRelayId: string;
  isWebSettings?: boolean;
}

type OverviewDateRange = {
  maxDate: Date;
  midDate: Date;
  minDate: Date;
};

const QUERY_DATE_FORMAT = "yyyy-MM-dd";

const initDateRange: OverviewDateRange = {
  maxDate: DateTime.now().minus({ days: 1 }).endOf("day").toJSDate(),
  midDate: DateTime.now().minus({ weeks: 1, days: 1 }).endOf("day").toJSDate(),
  minDate: DateTime.now().minus({ weeks: 2, days: 1 }).startOf("day").toJSDate(),
};

export const AdminPanelOverviewContainer = ({
  orgRelayId,
  isWebSettings,
}: IAdminPanelOverviewContainerProps) => {
  const [{ maxDate, midDate, minDate }, setDateRange] = React.useState(initDateRange);

  const handleChangeTimeframe = React.useCallback(
    ({ maxDate: newMaxDate, midDate: newMidDate, minDate: newMinDate }: OverviewDateRange) => {
      if (
        maxDate.valueOf() === newMaxDate.valueOf() &&
        midDate.valueOf() === newMidDate.valueOf() &&
        minDate.valueOf() === newMinDate.valueOf()
      ) {
        return; // bail early if dates have not changed
      }

      setDateRange({
        maxDate: newMaxDate,
        midDate: newMidDate,
        minDate: newMinDate,
      });
    },
    [maxDate, midDate, minDate],
  );

  const queryVariables = {
    orgRelayId,
    maxDate: DateTime.fromJSDate(maxDate).toFormat(QUERY_DATE_FORMAT),
    minDate: DateTime.fromJSDate(minDate).toFormat(QUERY_DATE_FORMAT),
  };

  let cachedOrg: IOrg;
  const environment = getCurrentEnvironment();

  return (
    <QueryRenderer
      environment={environment}
      query={adminPanelOverviewQuery}
      variables={queryVariables}
      render={(readyState: ReadyState) => {
        const { error, props } = readyState;
        if (error) {
          handleReadyStateError(error, "AdminPanelOverview");
          return null;
        }

        const org = (props && props.org) || null;

        if (org) {
          cachedOrg = org;
        }

        if (!cachedOrg) {
          return <Loader size="xl" sentiment="positive" className="cw-mt-auto cw-mb-auto" />;
        }

        const orgOverview = getOrgOverview(cachedOrg);
        const orgOverviewWithDisplayLabels = orgOverview && remapOrgOverviewLabels(orgOverview);

        const contextualTitle = isWebSettings ? "Analytics" : "Overview";

        const handleTimeframeChange = (timeframe: Timeframes) => {
          const currentPeriod = getTimeframeDates(timeframe);
          const previousPeriod = getTimeframeDates(timeframe, currentPeriod.minDate);

          // we need data from 2 full time periods for comparisons
          handleChangeTimeframe({
            maxDate: currentPeriod.maxDate,
            midDate: currentPeriod.minDate,
            minDate: previousPeriod.minDate,
          });
        };

        if (!orgOverviewWithDisplayLabels) {
          return null;
        }

        logger.info(
          `[Admin][Overview] ${minDate?.toDateString()} - ${midDate?.toDateString()} - ${maxDate?.toDateString()}`,
        );

        const timeframe = getTimeframeFromDates(minDate, maxDate);

        return (
          <div>
            <div className="cw-flex cw-items-start cw-justify-between">
              <Title>{contextualTitle}</Title>
              <div className="cw-flex cw-items-center">
                <TimeframeSelect value={timeframe} onChange={handleTimeframeChange} />
                <ExportableAnalyticsButton />
              </div>
            </div>
            <HeroStatGrid
              maxDate={maxDate}
              midDate={midDate}
              minDate={minDate}
              orgOverview={orgOverviewWithDisplayLabels}
            />
            <ChartGrid
              maxDate={maxDate}
              minDate={midDate}
              orgOverview={orgOverviewWithDisplayLabels}
            />
          </div>
        );
      }}
    />
  );
};
